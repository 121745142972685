import React, { useEffect, useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";

/* Components */
import { Primary, TextButton } from "../cta";
import { CheckBox, InputBox, NumberInput } from "../form";
// import { SocialButton } from '../socialLogin';
import { Toast } from "../alerts";
import md5 from "md5";

/* Services */
import EmailValidation from "../../services/validation/email";
import PhoneValidation from "../../services/validation/phone";

/* Services */
import services from "../../services/apis/services";

import SignupIllustration from "../../assets/images/authIllustrations/signup.svg";
import { AuthHeader } from "../../components/navigation";
import Swal from "sweetalert2";

import blueCircle from '../../assets/images/loader/blueCircle.gif';

const CreateAccountB2B = () => {
  const [toastMessage, setToastMessage] = useState(null);
  const router = useHistory();
  const [error, setError] = useState(null);
  const [signupLoader, setSignUpLoader] = useState(false);
  const [domainDetails, setDomainDetails] = useState([
    { domain: "", subdomains: ["", "", "", "", "", ""] },
  ]);
  const [adminSecret, setAdminSecret] = useState("");
  const [domainVerified, setDomainVerified] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // New state for OTP modal
  const [otp, setOtp] = useState();
  const [otpAPI, setOtpAPI] = useState(0);
  const [otpModal, setOtpModal] = useState(false);
  const [userCanAddDomain, setUserCanAddDomain] = useState(false);
  const [verifiedSecretKey, setVerifiedSecretKey] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, [error]);

  const SignUp = () => {
    setSignUpLoader(true);

    if (md5(adminSecret) === md5(process.env.REACT_APP_ADMIN_SECRET)) {
        let url = "action/add/signupB2B";

        let data = {
            fullName: name,
            email: email,
            countryCode: countryCode,
            mobileNo: number,
            password: password,
            cpassword: confirmPassword,
            admin_secret: md5(adminSecret),
            domain_details: domainDetails.map((detail) => ({
                domain: detail.domain,
                subdomains: detail.subdomains.filter((sub) => sub), // Filter out empty subdomains
            })),
        };

        services(true, url, data).then((response) => {
            if (response.data.status === 1) {
                // Clear input fields
                // setName("");
                // setEmail("");
                // setNumber("");
                // setPassword("");
                // setConfirmPassword("");
                // setAdminSecret("");
                // setDomainDetails([{ domain: "" }]);

                Swal.fire({
                    title: "Account Created",
                    text: "New account created successfully.",
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                setSignUpLoader(false);
                let error = response.data.msg; 

                if (error === "Email already exists") {
                    setError("This email is already registered. Please use a different email.");

                    const errorMessage = "This email is already registered. Please use a different email.";
                    setError(errorMessage);
                    setToastMessage({ message: errorMessage, type: 'error' });

                } else {
                    setError(error);
                }
                setSignUpLoader(false);
            }
        }).catch((error) => {
            setSignUpLoader(false);
            setError("An error occurred while processing your request. Please try again later.");
        });
    } else {
        let error = "Admin Secret Not Match Please Check Once";
        setError(error);
        setSignUpLoader(false);
    }
};


  // Show OTP modal
  const handleVerifyClick = () => {
    
    if (adminSecret === process.env.REACT_APP_ADMIN_SECRET) {
      sendOTP();
      setOtpModal(true);
    } else {
      let error = "Admin Secret Not Match Please Check Once";
      setError(error);
      setSignUpLoader(false);
    }
  };

  const sendOTP = () => {
    // setOTPLoader(true);

    let url = "action/otp/otpverifyB2B";

    let data = {
      admin_secret: md5(adminSecret),
    };

    services(true, url, data).then((response) => {
      let res = response.data.resData;
      if (res.status === 1) {
        setOtpAPI(res.data);
      } else {
      }
    });
  };

  const handleVerifyDomain = (event) => {
    // Prevent page reload
    event.preventDefault();
    verifyDomain();
  };

  const [domainErrors, setDomainErrors] = useState([]); // Array to store errors for domains and subdomains

  // const verifyDomain = async () => {
  //   let url = "action/verify/verifyDomainExp";

  //   let data = {
  //     admin_secret: md5(adminSecret),
  //     domain_details: domainDetails.map((detail) => ({
  //       domain: detail.domain,
  //       subdomains: detail.subdomains.filter((sub) => sub), // Filter out empty subdomains
  //     })),
  //   };

  //   setSignUpLoader(true); // Show loader during verification

  //   try {
  //     const response = await services(true, url, data);
  //     let res = response.data;
  //     if (response.data.resData.final_response === true) {
  //       setDomainVerified(true);
  //       setError(null);
  //       let domainErrors = response.data.resData.responseData.map(
  //         (domainResult) => {
  //           return {
  //             domainError: domainResult.main_domain.message,
  //             subdomainErrors: domainResult.subdomains.map(
  //               (sub) => sub.message
  //             ),
  //           };
  //         }
  //       );

  //       setDomainErrors(domainErrors);

  //     } else {
  //       const error = res.message || "Verification failed. Please try again.";
  //       setError(error);
  //       setDomainVerified(false);
  //       let domainErrors = response.data.resData.responseData.map(
  //         (domainResult) => {
  //           return {
  //             domainError: domainResult.main_domain.message,
  //             subdomainErrors: domainResult.subdomains.map(
  //               (sub) => sub.message
  //             ),
  //           };
  //         }
  //       );
  //       setDomainErrors(domainErrors);
  //     }
  //   } catch (error) {
  //     setError("An error occurred during verification.", error);
  //   } finally {
  //     setSignUpLoader(false); 
  //   }
  // };

  
  const verifyDomain = async () => {
    let url = "action/verify/verifyDomainExp";
  
    // Check for duplicates in the domainDetails array
    const domainErrors = validateDomains(domainDetails);
    if (domainErrors.length > 0) {
      setDomainErrors(domainErrors);
      setError("Duplicate domains or subdomains found same. Please correct them.");
      return; 
    }
  
    let data = {
      admin_secret: md5(adminSecret),
      domain_details: domainDetails.map((detail) => ({
        domain: detail.domain,
        subdomains: detail.subdomains.filter((sub) => sub), // Filter out empty subdomains
      })),
    };
  
    setSignUpLoader(true); 
  
    try {
      const response = await services(true, url, data);
      let res = response.data;
      if (response.data.resData.final_response === true) {
        setDomainVerified(true);
        setError(null);
        let domainErrors = response.data.resData.responseData.map(
          (domainResult) => {
            return {
              domainError: domainResult.main_domain.message,
              subdomainErrors: domainResult.subdomains.map((sub) => sub.message),
            };
          }
        );
  
        setDomainErrors(domainErrors);
      } else {
        const error = res.message || "Verification failed. Please try again.";
        setError(error);
        setDomainVerified(false);
        let domainErrors = response.data.resData.responseData.map(
          (domainResult) => {
            return {
              domainError: domainResult.main_domain.message,
              subdomainErrors: domainResult.subdomains.map((sub) => sub.message),
            };
          }
        );
        setDomainErrors(domainErrors);
      }
    } catch (error) {
      setError("An error occurred during verification.", error);
    } finally {
      setSignUpLoader(false);
    }
  };
  
  // Validation function to check for duplicate domains and subdomains
  const validateDomains = (domainDetails) => {
    let domainSet = new Set();
    let domainErrors = [];
    let errorMessage = ""; 
  
    domainDetails.forEach((detail) => {
      let subdomainSet = new Set();
      let domainError = null;
      let subdomainErrors = [];
  
      // Check for duplicate domains
      if (domainSet.has(detail.domain)) {
        domainError = `Duplicate domain: ${detail.domain}`;
      } else {
        domainSet.add(detail.domain);
      }
  
      detail.subdomains.forEach((sub) => {
        if (sub === detail.domain) {
          const subdomainError = `Subdomain "${sub}" cannot be the same as the domain.`;
          subdomainErrors.push(subdomainError);
  
          errorMessage = subdomainError;
          setError(errorMessage); 
          setToastMessage({ message: errorMessage, type: 'error' }); 
        } else if (subdomainSet.has(sub)) {
          const duplicateSubError = `Duplicate subdomain: ${sub}`;
          subdomainErrors.push(duplicateSubError);
  
          errorMessage = duplicateSubError;
          setError(errorMessage); 
          setToastMessage({ message: errorMessage, type: 'error' }); 
        } else {
          subdomainSet.add(sub);
        }
      });
  
      if (domainError || subdomainErrors.length > 0) {
        domainErrors.push({
          domainError,
          subdomainErrors,
        });
      }
    });
  
    return domainErrors;
  };
  
  

  const handleCloseModal = () => {
    setOtpModal(false);
  };

  // Handle OTP input change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Handle OTP verification
  const handleOtpVerify = () => {
    if (otp == otpAPI) {
      handleCloseModal();
      setUserCanAddDomain(true);
      setVerifiedSecretKey(true);
      // Toast.success("OTP Matched Successfully!");
      setToastMessage({ message: 'OTP Matched Successfully!', type: 'success' });
    } else {
      // Toast.error("OTP Not Matched");
      setToastMessage({ message: 'OTP Not Matched!', type: 'error' }); 
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const emailValid = await EmailValidation(email);

    const fullPhoneNumber = `${countryCode}${number}`.replace(/\s+/g, "");
    const phoneValid = await PhoneValidation(fullPhoneNumber);
    // const phoneValid = await PhoneValidation("+" + countryCode + "" + number);
    const passwordValid = password.length > 7;
    const passwordSame = password === confirmPassword;
    const nameValid = name.length > 1;
    const adminSecretValid = adminSecret.length > 0;

    if (!emailValid) return setError("Invalid email address.");
    if (!phoneValid) return setError("Please enter a valid phone number.");
    // if (!phoneValid) return setError("Please enter a valid phone number.");
    if (!passwordValid)
      return setError("Password should be at least 8 characters.");
    if (!passwordSame) return setError("Passwords do not match.");
    if (!nameValid) return setError("Please enter a valid name.");
    if (!adminSecretValid) return setError("Admin Secret is required.");

    SignUp();
  };


  useEffect(() => {
    setDomainVerified(false);
    validateFields();
  }, [domainDetails]); // Runs every time domainDetails changes

  // Function to validate domains and subdomains
  const validateFields = () => {
    if(verifiedSecretKey){
    
    for (let i = 0; i < domainDetails.length; i++) {
      const domain = domainDetails[i].domain;
      if (!domain) {
        setError(`Domain ${i + 1} cannot be empty`);

        // const errorMessage = `Domain ${i + 1} cannot be empty`;
        // setError(errorMessage);
        // setToastMessage({ message: errorMessage, type: 'error' });

        setIsDisabled(true);
        return false;
      }

      for (let j = 0; j < domainDetails[i].subdomains.length; j++) {
        const subdomain = domainDetails[i].subdomains[j];
        if (!subdomain) {
          setError(`Protective Domain ${i + 1}.${j + 1} cannot be empty`);

          // const errorMessage = `Protective Domain ${i + 1}.${j + 1} cannot be empty`;
          // setError(errorMessage);
          // setToastMessage({ message: errorMessage, type: 'error' });

          setIsDisabled(true);
          return false;
        }
      }
    }
    setError(null);
    setIsDisabled(false);
    return true;
  }
  };

  // Handle domain input change
  const handleDomainChange = (domainIndex, value) => {
    const updatedDomains = [...domainDetails];
    updatedDomains[domainIndex].domain = value;
    setDomainDetails(updatedDomains);
  };

  // Handle subdomain input change
  const handleSubdomainChange = (domainIndex, subIndex, value) => {
    const updatedDomains = [...domainDetails];
    updatedDomains[domainIndex].subdomains[subIndex] = value;
    setDomainDetails(updatedDomains);
  };

  // Add new domain
  const addDomainField = () => {
    if (validateFields()) {
      setDomainDetails([...domainDetails, { domain: "", subdomains: [""] }]);
    }
  };

  // Add new subdomain
  const addSubdomainField = (domainIndex) => {
    if (validateFields()) {
      const updatedDomains = [...domainDetails];
      updatedDomains[domainIndex].subdomains.push("");
      setDomainDetails(updatedDomains);
    }
  };

  // Remove subdomain
  const removeSubdomainField = (domainIndex, subIndex) => {
    if (subIndex >= 6) {
    const updatedDomains = [...domainDetails];
    updatedDomains[domainIndex].subdomains.splice(subIndex, 1);
    setDomainDetails(updatedDomains);
    }
  };

  // Remove domain
  const removeDomainField = (domainIndex) => {
    const updatedDomains = [...domainDetails];
    updatedDomains.splice(domainIndex, 1);
    setDomainDetails(updatedDomains);
  };

  return (
    <div className="auth-wrapper signup_header">
      <AuthHeader />

      <div className="container-fluid p-0" style={{ paddingTop: "100px" }}>
        <div className="row w-100 m-0 d-flex justify-content-center">
          {/* Left Column (Image) */}
          <div className="col-lg-5 col-md-5 col-sm-12 p-0 d-lg-block admin_signup_pg">
            <div className="auth-image-container">
              {/* <img src="/path/to/your-image.jpg" alt="Sign up" className="img-fluid w-100 h-100" style={{ objectFit: 'cover' }} /> */}
              <img
                src={SignupIllustration}
                className="img-fluid w-100 h-100 mt-5 signin_img"
                style={{ objectFit: "none" }}
              />
            </div>
          </div>

          {/* Right Column (Sign Up Form) */}
          <div className="col-lg-7 col-md-7 col-sm-12 p-5">
            <div className="e-auth-container w-100 d-flex align-items-center justify-content-center">
              <div className="e-auth-form-wrapper">
                <h3 className="text-center mb-3 signup_media">Sign Up</h3>
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <InputBox
                        label="Full Name*"
                        value={name}
                        type="text"
                        valueChange={(value) => {
                          setName(value);
                          setError(null);
                        }}
                        placeholder="Full Name"
                        required={true}
                      />
                    </div>

                    <div className="col-lg-6 pt-2">
                      <InputBox
                        label="Email*"
                        value={email}
                        type="email"
                        valueChange={(value) => {
                          setEmail(value);
                          setError(null);
                        }}
                        placeholder="Email Address"
                        required={true}
                      />
                    </div>

                    <div className="col-lg-6 pt-2">
                      <NumberInput
                        label="Phone Number*"
                        value={number}
                        type="tel"
                        valueChange={(value) => {
                          setNumber(value);
                          setError(null);
                        }}
                        placeholder="00000 00000"
                        defaultCode="+91"
                        onCodeChange={(val) =>
                          setCountryCode(val ? val : "+91")
                        }
                        required={true}
                      />
                    </div>

                    <div className="col-lg-6 pt-2">
                      <InputBox
                        label="Password*"
                        value={password}
                        type="password"
                        valueChange={(value) => {
                          setPassword(value);
                          setError(null);
                        }}
                        placeholder="New Password"
                        required={true}
                      />
                    </div>

                    <div className="col-lg-6 pt-2">
                      <InputBox
                        label="Confirm Password*"
                        value={confirmPassword}
                        type="password"
                        valueChange={(value) => {
                          setConfirmPassword(value);
                          setError(null);
                        }}
                        placeholder="Re-enter Password"
                        required={true}
                      />
                    </div>

                    <div className="col-lg-12 pt-2">
                      <InputBox
                        label="Admin Secret*"
                        value={adminSecret}
                        type="password"
                        valueChange={(value) => setAdminSecret(value)}
                        placeholder="Enter Admin Secret"
                        required={true}
                        readOnly={verifiedSecretKey}
                      />
                    </div>
                    <div className="d-flex justify-content-start mb-4">
                      {!verifiedSecretKey && (
                        <button
                          type="button"
                          className="btn btn-primary mt-2"
                          onClick={handleVerifyClick}
                        >
                          Verify Secret Key
                        </button>
                      )}
                    </div>

                    {userCanAddDomain
                      ? domainDetails.map((detail, domainIndex) => (
                          <>
                            <div
                              key={domainIndex}
                              className="col-lg-12 pt-2 mb-5 subDomin_sectionUpdiv"
                            >
                              <div className="row d-flex subprotectiveDomain mt-2">
                                <div className="col-md-9 col-lg-9 col-sm-9">
                                <InputBox
                                  label={`Domain ${
                                    domainIndex + 1
                                  }`}
                                  key={`${domainIndex}`}
                                  value={detail.domain}
                                  type="text"
                                  valueChange={(value) =>
                                    handleDomainChange(domainIndex, value)
                                  }
                                  placeholder={`Enter Domain ${
                                    domainIndex + 1
                                  }`}
                                  required={true}
                                  hasError={!detail.domain}
                                  setError={
                                    domainErrors[domainIndex]?.domainError
                                  } 
                                />
                                </div>
                                <div className="col-md-3 col-lg-3 col-sm-3">
                                {domainErrors[domainIndex]?.domainError ===
                                false ? (
                                  <span style={{ color: "red" }}>
                                    Main domain has expired or information is
                                    unavailable{" "}
                                  </span>
                                ) : (
                                  domainVerified ?
                                  <>
                                    <input
                                      type="checkbox"
                                      id="customCheckbox"
                                    />

                                    <label
                                      htmlFor="customCheckbox"
                                      className="custom-checkbox"
                                    ></label>
                                  </>
                                  :null
                                  
                                )}
                                </div>
                              </div>
                              {/* Flexbox container for subdomains */}
                              <div className="subdomains-container d-flex flex-wrap justify-content-center subDomin_section">
                                {detail.subdomains.map((sub, subIndex) => (
                                  <div
                                    key={subIndex}
                                    className={`subdomain-item d-flex align-items-center ${
                                      subIndex === 0 ? "custom-margin" : ""
                                    }`}
                                  >
                                    {/* <div className="d-flex subprotectiveDomain">
                                      <InputBox
                                        label={`Protective Domain ${
                                          domainIndex + 1
                                        }.${subIndex + 1}`}
                                        key={`${domainIndex}`}
                                        value={sub}
                                        type="text"
                                        valueChange={(value) =>
                                          handleSubdomainChange(
                                            domainIndex,
                                            subIndex,
                                            value
                                          )
                                        }
                                        placeholder={`Enter Subdomain ${
                                          domainIndex + 1
                                        }.${subIndex + 1}`}
                                        required={true}
                                        className="subdomain-input"
                                        hasError={!sub}
                                        errorMessage={
                                          domainErrors[domainIndex]
                                            ?.subdomainErrors[subIndex]
                                        } // Display subdomain error
                                      />

                                      {domainErrors[domainIndex]
                                        ?.subdomainErrors[subIndex] ===
                                      false ? (
                                        <span style={{ color: "red" }}>
                                          Subdomain has expired or information
                                          is unavailable
                                        </span>
                                      ) : (
                                        domainVerified ?
                                        <>
                                          <input
                                            type="checkbox"
                                            id="customCheckbox"
                                          />

                                          <label
                                            htmlFor="customCheckbox"
                                            className="custom-checkbox"
                                          ></label>
                                        </>
                                        :null
                                      )}
                                    </div> */}

                                  {/* <div className="d-flex subprotectiveDomain flex-column">
                                    <div className="d-flex flex-wrap">
                                      <div className="subdomain-wrapper">
                                        <InputBox
                                          label={`Protective Domain ${domainIndex + 1}.${subIndex + 1}`}
                                          key={`${domainIndex}`}
                                          value={sub}
                                          type="text"
                                          valueChange={(value) =>
                                            handleSubdomainChange(domainIndex, subIndex, value)
                                          }
                                          placeholder={`Enter Subdomain ${domainIndex + 1}.${subIndex + 1}`}
                                          required={true}
                                          className="subdomain-input"
                                          hasError={!sub}
                                        />

                                        {domainErrors[domainIndex]?.subdomainErrors[subIndex] === false ? (
                                          <span className="error-message">
                                            Subdomain has expired or information is unavailable
                                          </span>
                                        ) : (
                                          domainVerified ? (
                                            <>
                                              <input type="checkbox" id="customCheckbox" />
                                              <label htmlFor="customCheckbox" className="custom-checkbox"></label>
                                            </>
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  </div> */}

                              <div className="d-flex subprotectiveDomain">
                                {domainErrors[domainIndex]?.subdomainErrors[subIndex] === false ? (
                                  // Render this block if subdomainErrors[subIndex] is false
                                  <div className="d-flex subprotectiveDomain flex-column position-relative">
                                    <div className="d-flex flex-wrap">
                                      <div className="subdomain-wrapper position-relative">
                                        <InputBox
                                          label={`Protective Domain ${domainIndex + 1}.${subIndex + 1}`}
                                          key={`${domainIndex}`}
                                          value={sub}
                                          type="text"
                                          valueChange={(value) =>
                                            handleSubdomainChange(domainIndex, subIndex, value)
                                          }
                                          placeholder={`Enter Subdomain ${domainIndex + 1}.${subIndex + 1}`}
                                          required={true}
                                          className="subdomain-input"
                                          hasError={!sub}
                                        />
                                        <span className="error-message">
                                          Subdomain has expired or information is unavailable
                                        </span>

                                        {subIndex > 5 && (
                                          <button
                                            type="button"
                                            className="close-btn"
                                            onClick={() => removeSubdomainField(domainIndex, subIndex)}
                                          >
                                            &times;
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  // Render this block if subdomainErrors[subIndex] is not false (either undefined or true)
                                  <>
                                  <div className="subdomain-wrapper position-relative">
                                    {domainVerified ? (
                                      <>
                                        <input type="checkbox" id="customCheckbox" />
                                        <label htmlFor="customCheckbox" className="custom-checkbox customCheckbox_subdomain"></label>
                                      </>
                                    ) : null}
                                    <InputBox
                                      label={`Protective Domain ${domainIndex + 1}.${subIndex + 1}`}
                                      key={`${domainIndex}`}
                                      value={sub}
                                      type="text"
                                      valueChange={(value) =>
                                        handleSubdomainChange(domainIndex, subIndex, value)
                                      }
                                      placeholder={`Enter Subdomain ${domainIndex + 1}.${subIndex + 1}`}
                                      required={true}
                                      className="subdomain-input"
                                      hasError={!sub}
                                      errorMessage={domainErrors[domainIndex]?.subdomainErrors[subIndex]} // Display subdomain error
                                    />

                                    {/* {domainVerified ? (
                                      <>
                                        <input type="checkbox" id="customCheckbox" />
                                        <label htmlFor="customCheckbox" className="custom-checkbox"></label>
                                      </>
                                    ) : null} */}

                                    {/* Remove button only appears if subIndex > 5 */}
                                    {subIndex > 5 && (
                                      <button
                                        type="button"
                                        className="close-btn"
                                        onClick={() => removeSubdomainField(domainIndex, subIndex)}
                                        style={{
                                          top: domainVerified ? '60%' : '50%'
                                        }}
                                      >
                                        &times;
                                      </button>
                                    )}
                                    </div>
                                  </>
                                )}
                              </div>



                                    {/* <input type="checkbox" className="form-check-input ms-2" id="exampleCheck1"></input> */}

                                    {/* {subIndex > 5 && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          removeSubdomainField(
                                            domainIndex,
                                            subIndex
                                          )
                                        }
                                        className="btn btn-danger ml-2 remove-subdomain-btn ms-2 mb-5"
                                      >
                                        Remove
                                      </button>
                                    )} */}
                                  </div>
                                ))}
                              </div>

                              {/* Render Add Protective Domain button only once, after the last subdomain */}
                              {/* {detail.subdomains.length < 6 && ( */}
                                <div className="add-subdomain-btn-container">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      addSubdomainField(domainIndex)
                                    }
                                    className="btn btn-secondary ml-2 mb-2"
                                  >
                                    Add Protective Domain
                                  </button>
                                </div>
                              {/* )} */}

                              {/* Optionally render Remove Domain button for each domain */}
                              {domainDetails.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() => removeDomainField(domainIndex)}
                                  className="btn btn-danger mt-2 ml-2 mb-3 ms-4"
                                >
                                  Remove Domain
                                </button>
                              )}
                            </div>
                          </>
                        ))
                      : null}
                    {( verifiedSecretKey) && (
                      <div className="col-md-5">
                        <button
                          type="button"
                          onClick={addDomainField}
                          className="btn addAnotherDomain mt-3"
                        >
                          Add Another Domain
                        </button>
                      </div>
                    )}

                    <div className="col-lg-12 pt-3">
                      {error && <p className="text-danger">{error}</p>}
                    </div>

                    {/* {domainVerified ? (
                      <>
                        <div className="col-lg-12 pt-3">
                          {name &&
                          email &&
                          number &&
                          password &&
                          confirmPassword &&
                          adminSecret ? (
                            <Primary
                              className={signupLoader ? "e-btn-loader" : ""}
                              name="Create Account"
                              full_width={true}
                              handleClick={(e) => handleSignup(e)}
                              disabled={isDisabled || signupLoader}
                            />
                          ) : (
                            <Primary
                              name="Create Account"
                              full_width={true}
                              disabled={true}
                            />
                          )}
                        </div>
                      </>
                    )  */}
                    
                    {domainVerified ? (
                      <>
                        <div className="col-lg-12 pt-3">
                          {name && email && number && password && confirmPassword && adminSecret ? (
                            <>
                              
                              {signupLoader ? (
                                <div className="loader-wrapper" style={{ textAlign: 'center' }}>
                                  <img src={blueCircle} alt="Loading..." className="loader-gif" />
                                </div>
                              ) : (
                                <Primary
                                  className="e-btn"
                                  name="Create Account"
                                  full_width={true}
                                  handleClick={(e) => handleSignup(e)}
                                  disabled={isDisabled || signupLoader} 
                                />
                              )}
                            </>
                          ) : (
                            <Primary
                              name="Create Account"
                              full_width={true}
                              disabled={true} 
                            />
                          )}
                        </div>
                      </>
                    )
                    : (
                      <>
                        {/* {verifiedSecretKey && (
                          <div className="col-lg-12 pt-3">
                            {name &&
                            email &&
                            number &&
                            password &&
                            confirmPassword &&
                            verifiedSecretKey ? (
                              <Primary
                                className={signupLoader ? "e-btn-loader" : ""}
                                name="Verify Domain"
                                full_width={true}
                                handleClick={(e) => handleVerifyDomain(e)}
                                disabled={isDisabled || signupLoader}
                              />
                            ) : (
                              <Primary
                                name="Please complete above information first."
                                full_width={true}
                                disabled={true}
                              />
                            )}
                          </div>
                        )} */}

                            {verifiedSecretKey && (
                              <div className="col-lg-12 pt-3">
                                {name && email && number && password && confirmPassword && verifiedSecretKey ? (
                                  <>
                                    {/* Replace button with loading GIF when signupLoader is true */}
                                    {signupLoader ? (
                                      <div className="loader-wrapper" style={{ textAlign: 'center' }}>
                                        <img src={blueCircle} alt="Loading..." className="loader-gif" />
                                      </div>
                                    ) : (
                                      <Primary
                                        className="e-btn"
                                        name="Verify Domain"
                                        full_width={true}
                                        handleClick={(e) => handleVerifyDomain(e)}
                                        disabled={isDisabled || signupLoader} // Disable the button during loading
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Primary
                                    name="Please complete above information first."
                                    full_width={true}
                                    disabled={true} // Button disabled until form is complete
                                  />
                                )}
                              </div>
                            )}

                      </>
                    )}

                    <div className="col-lg-12 pt-3">
                      <div className="d-flex align-items-center">
                        <p className="mb-0 me-1">Existing User?</p>
                        <TextButton
                          name="Login Now"
                          blue={true}
                          small={true}
                          handleClick={() => {
                            setName("");
                            setEmail("");
                            setNumber("");
                            setPassword("");
                            setConfirmPassword("");
                            setCountryCode("+91");
                            router.push("/auth/login");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTP Modal */}
      {otpModal && <div className="modal-backdrop fade show"></div>}
      <div
        className={`modal fade ${otpModal ? "show" : ""}`}
        id="staticBackdrop"
        tabIndex="-1"
        role="dialog"
        style={{ display: otpModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={customStyles.modalContent}>
            <div className="modal-header" style={customStyles.modalHeader}>
              <h5 className="modal-title" style={customStyles.modalTitle}>
                OTP Verification
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body" style={customStyles.modalBody}>
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                id="otp"
                className="form-control mt-2"
                value={otp}
                onChange={handleOtpChange}
                placeholder="Enter OTP"
              />
            </div>
            <div className="modal-footer" style={customStyles.modalFooter}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleOtpVerify}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </div>

        {toastMessage && <Toast data={toastMessage.message} type={toastMessage.type} />}

        {toastMessage && setTimeout(() => setToastMessage(null), 4000)}

    </div>
  );
};

// Custom styles for the modal
const customStyles = {
  modalContent: {
    borderRadius: "10px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    border: "none",
  },
  modalHeader: {
    borderBottom: "1px solid #dee2e6",
    padding: "1.5rem",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  modalTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#333",
  },
  modalBody: {
    padding: "1.5rem",
    fontSize: "1rem",
    color: "#666",
  },
  modalpara: {
    fontsize: "0.9rem",
    color: "#333",
  },
  modalFooter: {
    borderTop: "1px solid #dee2e6",
    padding: "0.5rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  cancelButton: {
    backgroundColor: "#6c757d",
    borderColor: "#6c757d",
    color: "#fff",
    borderRadius: "5px",
    padding: "0.5rem 1rem",
  },
  deleteButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#fff",
    borderRadius: "5px",
    padding: "0.5rem 1rem",
  },
};

export default withRouter(CreateAccountB2B);
