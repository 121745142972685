import React, { useEffect, useState } from "react";
import styles from "../../styles/components/ReportCards.module.scss";
import { TextButton } from "../cta";
import { SingleSelect } from "../form";

// Utility function to format date
const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
};

// Utility function to get month from date
const getMonthFromDate = (dateString) => {
  const date = new Date(dateString);
  return date.getMonth(); // Returns month index (0 for January, 11 for December)
};

// Utility function to sort issues by date
const sortIssuesByDate = (issues, sortOrder) => {
  if (sortOrder === "asc") {
    return [...issues].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  }
  if (sortOrder === "desc") {
    return [...issues].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }
  return issues; // Default order if no sorting is selected
};

// Utility function for pagination
const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const ReportCards = (props) => {
  
  const [expanded, setExpanded] = useState(props.isExpanded);
  const [selectedMonth, setSelectedMonth] = useState(null); // State to track selected month
  const [sortOrder, setSortOrder] = useState("asc"); // State to track sorting order
  const [currentPage, setCurrentPage] = useState(1); // State to track current page
  const pageSize = 10; // Number of records per page

  const months = [
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 },
  ];

  const sortOptions = [
    { label: "Date Asc", value: "asc" },
    { label: "Date Desc", value: "desc" },
  ];

  useEffect(() => {
    // Update expanded state based on prop
    setExpanded(props.isExpanded);
  }, [props.isExpanded]);

  const handleExpandClick = () => {
    // If the card is already expanded, collapse it; otherwise, expand it
    if (props.onExpand) {
      props.onExpand(props.data._id); // Pass domain name to parent
    }
  };

  const collapseAccordian = () => {
    let currentState = expanded;
    setExpanded(!currentState);
    if (props.onExpand) {
      props.onExpand(props.data._id); // Notify parent of current domain name
    }
  };


  // Filter issues by selected month
  const filteredIssues = props.data.issues.filter((issue) => {
    if (selectedMonth === null) return true; // If no month is selected, show all issues
    return getMonthFromDate(issue.createdAt) === selectedMonth;
  });

  // Sort issues by selected sort order
  const sortedIssues = sortIssuesByDate(filteredIssues, sortOrder);

  // Paginate sorted issues
  const paginatedIssues = paginate(sortedIssues, pageSize, currentPage);

  // Total number of pages
  const totalPages = Math.ceil(sortedIssues.length / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const convertToCSV = (issues) => {
    // Define the CSV headers
    const headers = ["SR No.", "Issue Title", "Status", "Found Date", "Last Updated Date", "Description"];
    
    // Create a CSV string
    const rows = issues.map((issue, index) => [
      (currentPage - 1) * pageSize + index + 1, // SR No.
      issue.issue, // Issue Title
      issue.status, // Status
      formatDate(issue.createdAt), // Found Date
      formatDate(issue.updatedAt), // Last Updated Date
      issue.desc // Description
    ]);
  
    // Combine headers and rows into CSV format
    const csvContent = [headers, ...rows].map(row => row.join(",")).join("\n");
    
    return csvContent;
  };

  
  const downloadCSV = (issues) => {

     // Get today's date in the desired format
  const today = new Date();
  const formattedDate = today.toLocaleDateString(); // Adjust format as needed
  
  // Create the CSV header
  const header = 
  `"--------------------------------------------------------------------------------",,,,,
                              Web23 Domain Issues Report,,,,,
  --------------------------------------------------------------------------------,,,,,
  Domain: ${props.data.domain_name},,,,,
  Date: ${formattedDate},,,,,
  --------------------------------------------------------------------------------,,,,,
  `;
  

    const csvContent = convertToCSV(issues);
    
     // Combine header and CSV content
    const fullCSVContent = header + csvContent;

    // Create a Blob with the CSV content
    const blob = new Blob([fullCSVContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create a link element
    const link = document.createElement('a');
    
    // Set the download URL
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', props.data.domain_name+'.csv');
    
    // Append the link to the body (it must be in the document for the download to work)
    document.body.appendChild(link);
    
    // Trigger the download
    link.click();
    
    // Clean up
    document.body.removeChild(link);
  };
  
  // TextButton_e_t_btn__3wNQs
  const TextButton = ({ download, name, onClick }) => (
    <button onClick={onClick} className="btn TextButton_fordwld">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: '6px' }}
      >
        <path
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM12 17L7 12L8.41 10.59L11 13.17V7H13V13.17L15.59 10.59L17 12L12 17Z"
          fill="#fff"
        />
      </svg>
      {name}
    </button>
  );

  return (
    <div>
      {props.data ? (
        <div className={`${styles.e_reports_wrapper} d-flex w-100 flex-column`}>
          <div
            className={
              expanded
                ? `${styles.e_reports_header} ${styles.e_report_expanded} position-relative w-100`
                : `${styles.e_reports_header} position-relative w-100`
            }
            onClick={() => collapseAccordian()}
          >
            <div className="row">
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div className={styles.e_head_icon}>
                    {props.icon ? (
                      <img src={props.icon} alt="card icon" />
                    ) : null}
                  </div>
                  <h3
                    className={
                      props.socialMedia
                        ? "mb-0 ms-2 text-capitalize"
                        : "mb-0 ms-2 text-lowercase"
                    } data-domain-name={props.data._id} 
                  >
                    {props.data.domain_name}
                  </h3>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex w-100 justify-content-end">
                  <div
                    className={styles.e_expand_icon}
                    onClick={handleExpandClick}
                  ></div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.e_header_border} position-absolute`}
            ></div>
          </div>
          <div
            className={
              expanded
                ? `${styles.e_reports_body_wrapper} ${styles.e_expanded} w-100`
                : `${styles.e_reports_body_wrapper} w-100`
            }
          >
            <div className={styles.e_reports_body}>
              <div
                className={`container-fluid p-3 ${styles.report_table_title}`}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className={`${styles.e_reports_filters} row`}>
                      <div className="col-lg-7">
                        <div className="w-100 d-flex align-items-center">
                          <h3>Summary</h3>
                          <div className="w-100 ps-2">
                            <SingleSelect
                              placeholder="Select Month"
                              options={months}
                              selectchange={(selected) => {
                                setSelectedMonth(selected.value); // Set selected month
                                setCurrentPage(1); // Reset to first page
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="w-100 d-flex align-items-center">
                          <span className={styles.e_sort_label}>Sort by:</span>
                          <div className="w-100 ps-2">
                            <SingleSelect
                              placeholder="Date Asc"
                              options={sortOptions}
                              selectchange={(selected) => {
                                setSortOrder(selected.value); // Set sort order
                                setCurrentPage(1); // Reset to first page
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className={`d-flex w-100 justify-content-end ${styles.report_table_download_btn}`}
                    >
                      <TextButton
                      download={true}
                      name="Download"
                      onClick={() => downloadCSV(paginatedIssues)} // Trigger download on click
                    />

                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.e_table_wrapper} mt-2`}>
                <div className={`${styles.e_table_container}`}>
                  <div className={`${styles.e_table_head} w-100 py-3`}>
                    <div className="w-100 px-3">
                      <div className="row">
                        <div className="col-1">
                          <h6>SR No.</h6>
                        </div>
                        <div className="col-3">
                          <h6>Issue Title</h6>
                        </div>
                        <div className="col-1">
                          <h6>Status</h6>
                        </div>
                        <div className="col-2">
                          <h6>Found Date</h6>
                        </div>
                        <div className="col-2">
                          <h6>Last Updated Date</h6>
                        </div>
                        <div className="col-3">
                          <h6>Description</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.e_table_body} w-100`}>
                    {paginatedIssues &&
                      paginatedIssues.map((issue, index) => {
                        const srNo = (currentPage - 1) * pageSize + index + 1;
                        return (
                          <div
                            className={
                              index < paginatedIssues.length - 1
                                ? "w-100 px-3 e-border-bottom"
                                : "w-100 px-3"
                            }
                            key={`issue-${index}-${props.data._id}`}
                          >
                            <div className="row py-3">
                              <div className="col-1">{srNo}</div>
                              <div className="col-3">{issue.issue}</div>
                              <div className="col-1">{issue.status}</div>
                              <div className="col-2">
                                <div className="d-flex flex-column">
                                  <span>{formatDate(issue.createdAt)}</span>
                                  <span className={styles.e_fw_small}>
                                    {issue.found_time}
                                  </span>
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="d-flex flex-column">
                                  <span>{formatDate(issue.updatedAt)}</span>
                                  <span className={styles.e_fw_small}>
                                    {issue.solved_time}
                                  </span>
                                </div>
                              </div>
                              <div className="col-3">{issue.desc}</div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* Pagination Controls */}
              <div className="d-flex justify-content-between align-items-center mt-3 mb-3 mx-3">
                <button
                  className="btn btn-outline-primary"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-outline-primary"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReportCards;
