import React, { useState } from "react";
import { useHistory } from 'react-router';

/* Import style */
import styles from '../../styles/components/ManageDomains.module.scss';

import { Secondary } from '../cta';
import { getServices } from "../../services/apis/services";
import { Toast } from "../../components/alerts";
import Swal from "sweetalert2"; 

const ManageDomains = (props) => {
    const history = useHistory();
    const [toastMessage, setToastMessage] = useState(null);

    const navigateToManagement = () => {
        let link = `/domain/${props.type}/manage?domain=${props.domain}`;
        history.push(link);
    };

    const handleDeleteClick = () => {
        // Show SweetAlert confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            html: `Do you really want to delete the domain <strong>"${props.domain}"</strong> ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                confirmDelete();
            }
        });
    };

    const confirmDelete = async () => {
        try {
            const deleteUrl = "domains/delete2";
            const token = localStorage.getItem("web23_token");
            const deletePayload = {
                domain_name: props.domain,
            };

            const deleteResponse = await getServices(deleteUrl, token, deletePayload);
            if (deleteResponse.data.status === 1 && deleteResponse.data.resData.nModified > 0) {
                // console.log(`Domain ${props.domain} marked as deleted.`);
                window.scrollTo(0, 0);
                history.push('/domains', { toastMessage: 'Domain successfully deleted!' });
            } else {
                console.error('Failed to delete domain:', deleteResponse.message);
                Swal.fire('Error', 'There was a problem deleting the domain.', 'error');
            }
        } catch (error) {
            console.error('Error deleting domain:', error);
            Swal.fire('Error', 'There was an error deleting the domain.', 'error');
        }
    };

    return (
        <div className={`${styles.e_wrapper} e-px-32 e-py-32 w-100`}>
            <h3 className="mb-0 pb-4">Manage Domain</h3>
            <div className="row pt-2 gy-3">
                <div className="col-lg-3 col-md-6">
                    <Secondary full_width="true" blue="true" large="true" name={props.type === "web3" ? "Manage Domain" : "Manage DNS"} handleClick={navigateToManagement} />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Secondary handleClick={handleDeleteClick} full_width="true" red="true" large="true" name="Delete Domain" />
                </div>
            </div>

            {toastMessage && <Toast message={toastMessage.message} type={toastMessage.type} />}
        </div>
    );
};

export default ManageDomains;
