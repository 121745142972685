import React, { useState } from "react";

import Icon from '../../assets/images/profile/settings-icon.png'
import { Toast } from "../../components/alerts";
import { Primary } from "../../components/cta";
import { InputBox } from "../../components/form";
import services from "../../services/apis/services";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'; // Import SweetAlert

import { AuthIllustrationWrapper } from '../../components/auth/illustrationWrapper';
import { AuthHeader } from '../../components/navigation';
import SignupIllustration from '../../assets/images/authIllustrations/signup.svg';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    const handleLogout = () => {
        Cookies.remove("web23_token");
        window.location.href = "/auth/login";
    }

    /* change password */
    // const handleChangePassword = () => { 

    //     setError(null);
    //     if (newPassword !== confirmPassword) {
    //         setError("New Password and Re-enter New Password do not match");
    //         setTimeout(() => {
    //             setError(null);
    //         }, 5000);
    //         return;
    //     }
    //     setLoader(true);
    //     let url = 'action/change/login';

    //     let data = {
    //         'token': localStorage.getItem("web23_token"),
    //         'passwordOld': currentPassword,
    //         'passwordNew': newPassword,
    //         'cpassword': confirmPassword,
    //     }

    //     services(true, url, data).then((response) => {
    //         let res = response.data;

    //         if (res.status === 1) {
    //             setLoader(false);
    //             Cookies.remove('web23_user');

    //             Swal.fire({
    //                 title: "Password Change",
    //                 text: "Password has been changed relogin with new credentials to use your account",
    //                 icon: "success"
    //               });

                
    //         } else {
    //             setError(res.msg || res.resData[0]?.msg);
    //             setLoader(false);
    //         }
    //     });
    // }

    const handleChangePassword = () => { 
        setError(null);
        if (newPassword !== confirmPassword) {
            setError("New Password and Re-enter New Password do not match");
            setTimeout(() => {
                setError(null);
            }, 5000);
            return;
        }
        setLoader(true);
        let url = 'action/change/login';
    
        let data = {
            'token': localStorage.getItem("web23_token"),
            'passwordOld': currentPassword,
            'passwordNew': newPassword,
            'cpassword': confirmPassword,
        }
    
        services(true, url, data).then((response) => {
            let res = response.data;
    
            if (res.status === 1) {
                setLoader(false);
                Cookies.remove('web23_user');
    
                Swal.fire({
                    title: "Password Change",
                    text: "Password has been changed. Please re-login with new credentials to use your account.",
                    icon: "success",
                    showCloseButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleLogout(); // Call handleLogout on OK click
                    }
                });
            } else {
                setError(res.msg || res.resData[0]?.msg);
                setLoader(false);
            }
        });
    }
    

    return (
        <>
        <div className="w-100">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 admin_signup_pg gx-0">
                    <div className="signup_header">
                        <AuthHeader />
                    </div>
                    <div className="d-lg-block admin_signup_pg">
                        <div className="auth-image-container">
                            <img src={ SignupIllustration} className="img-fluid w-100 h-100 mt-5 signin_img" style={{ objectFit: 'none' }} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-5 p-5">
                    <div className="w-100 e-card-wrapper e-px-32 e-py-32 change_password_first">
                        <div className="d-flex align-items-center pb-3">
                            <div className="e-card-icon">
                                <img src={Icon} alt="settings icon" />
                            </div>
                            <h3 className="mb-0 ps-1">Change Password</h3>
                        </div>
                        <div className="w-100 pt-3">
                            <InputBox
                                label={<>Current Password <span className="e-warning-color">*</span></>}
                                value={currentPassword}
                                type="password"
                                valueChange={(value) => {
                                    setCurrentPassword(value);
                                    setError(null);
                                }}
                                placeholder="Current Password"
                                required={true} />
                        </div>
                        <div className="w-100 pt-3">
                            <InputBox
                                label={<>New Password <span className="e-warning-color">*</span></>}
                                value={newPassword}
                                type="password"
                                valueChange={(value) => {
                                    setNewPassword(value);
                                    setError(null);
                                }}
                                placeholder="New Password"
                                required={true} />
                        </div>
                        <div className="w-100 pt-3">
                            <InputBox
                                label={<>Re-enter New Password <span className="e-warning-color">*</span></>}
                                value={confirmPassword}
                                type="password"
                                valueChange={(value) => {
                                    setConfirmPassword(value);
                                    setError(null);
                                }}
                                placeholder="Re-enter New Password"
                                required={true} />
                        </div>
                        <div className="w-100 d-flex pt-3">
                            {
                                currentPassword && confirmPassword && newPassword ?
                                    <Primary
                                        name="Save"
                                        medium={true}
                                        className={loader ? "e-btn-loader" : ""}
                                        handleClick={handleChangePassword} // Updated to new function name
                                    />
                                    :
                                    <Primary
                                        name="Save"
                                        medium={true}
                                        disabled={true}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
        </>
    );
}

export default ChangePassword; // Export with the new component name
