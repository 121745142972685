
// export default async function PhoneValidation(phone) {
// 	var phoneNumberPattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
// 	return phoneNumberPattern.test(phone);
// }

export default async function PhoneValidation(phone) {
	// This regex validates international phone numbers with country code
	const phoneNumberPattern = /^\+(?:[0-9]{1,3})[0-9]{6,14}$/;
	
	// Ensure that the phone number does not contain any spaces
	const sanitizedPhone = phone.replace(/\s+/g, "");
  
	// Test against the regex
	return phoneNumberPattern.test(sanitizedPhone);
  }
  
