/*Package importing*/
import React, { useState, useEffect } from "react";
import { getServices } from "../../services/apis/services";
import { useHistory } from "react-router";

/*import style*/
import styles from "../../styles/components/DashboardReport.module.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/* components */
import { HeathIndicatorBadge } from "../badges";
import { Primary, TextButton } from "../cta";
import { SingleSelect } from "../form";
import DonutChart from "../charts/pie";
import PieChart from "../charts/pieChart";
import LineChart from "../charts/line";
import Loader from "../others/loader";

/* assests */
import Facebook from "../../assets/images/icons/facebook-32.svg";
import Linkedin from "../../assets/images/icons/linkedin-32.svg";
import Twitter from "../../assets/images/icons/twitter-32.svg";
import Instagram from "../../assets/images/icons/Instagram-32.svg";
import Success from "../../assets/images/icons/tick-icon-18.svg";
import Warning from "../../assets/images/icons/warning-icon-18.svg";

/* services */
import services from "../../services/apis/services";
import { Toast } from "../alerts";
import { Router } from "react-router-dom";
import { withRouter } from "react-router-dom";

import {
  LoginSocialFacebook,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialTwitter,
} from "reactjs-social-login";
// import { FacebookLoginButton, InstagramLoginButton, LinkedInLoginButton, TwitterLoginButton, YahooLoginButton  } from "react-social-login-buttons";

const DashboardReport = (props) => {
    console.log("props")
    console.log(props)
  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const twitterAppId = process.env.REACT_APP_LINKEDIN_APP_ID;
  const linkedinAppId = process.env.REACT_APP_LINKEDIN_APP_ID;
  const instagramAppId = process.env.REACT_APP_INSTAGRAM_APP_ID;

  const [isLoadedDns, setIsLoadedDns] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedSocialAlike, setIsLoadedSocialAlike] = useState(false);

  const [profileFacebook, setProfileFacebook] = useState();
  const [profileFacebookAPIData, setProfileFacebookAPIData] = useState();

  const [profileInstagram, setProfileInstagram] = useState();
  const [profileInstagramAPIData, setProfileInstagramAPIData] = useState();

  const [profileTwitter, setProfileTwitter] = useState();
  const [profileTwitterAPIData, setprofileTwitterAPIData] = useState();

  const [profileLinkedIn, setProfileLinkedIn] = useState();
  const [profileLinkedInAPIData, setProfileLinkedInAPIData] = useState();

  const [
    looksAlikeSocialMediaLoadedYearMonthWise,
    setlooksAlikeSocialMediaYearMonthwiseLoaded,
  ] = useState(false);
  const [
    looksAlikeSocialMediaYearMonthWiseTotalCount,
    setlooksAlikeSocialMediaYearMonthWiseTotalCount,
  ] = useState(0);

  const [
    looksAlikeSocialMediaYearMonthWise,
    setlooksAlikeSocialMediaYearMonthWise,
  ] = useState();

  const [modalTitle, setModalTitle] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [domainsDataRecomanded, setDomainsDataRecomanded] = useState([]); // To store domains data
  const [socialMediaAlikeCount, setSocialMediaAlikeCount] = useState([]); // To store domains data

  const router = useHistory();
  const [domainsDataActive, setDomainsDataActive] = useState([]);
  const [domainNameSelected, setDomainNameSelected] = useState(""); // Manage selection if needed
  const [
    dataRecomandedEchrochmentCount,
    setDomainsDataRecomandedEchrochmentCount,
  ] = useState([]); // Manage selection if needed
  const [domainsDataRecomandedAlikeCount, setDomainsDataRecomandedAlikeCount] =
    useState([]); // Manage selection if needed
  const [domainNameSelectedForDNSRecord, setDomainNameSelectedForDNSRecord] =
    useState(""); // Manage selection if needed

  const [dnsRecordForDomain, setDnsRecordForDomain] = useState([]); // Manage selection if needed
  const [expiryDateDomain, setExpiryDateDomain] = useState(""); // Manage selection if needed

  const [selected, setSelected] = useState();

  const openModal = async (modalTitle) => {
    let social_account_name_front = "";
    if (modalTitle == "Facebook Login") {
      social_account_name_front = "facebook";
    } else if (modalTitle == "Instagram Login") {
      social_account_name_front = "instagram";
    } else if (modalTitle == "LinkedIn Login") {
      social_account_name_front = "linkedin";
    } else if (modalTitle == "Twitter Login") {
      social_account_name_front = "twitter";
    }

    let dataToBeSubmit = {
      social_account_name: social_account_name_front,
    };

    const url = "socialaccounts/delete";
    const token = localStorage.getItem("web23_token");
    const response = await getServices(url, token, dataToBeSubmit);
    if (response.data.status === 1) {
      if (modalTitle == "Facebook Login") {
        setProfileFacebook();
      } else if (modalTitle == "Instagram Login") {
        setProfileInstagram();
      } else if (modalTitle == "LinkedIn Login") {
        setProfileLinkedIn();
      } else if (modalTitle == "Twitter Login") {
        setProfileTwitter();
      }
    }
  };

  useEffect(() => {
    const updateSocialMediaData = async () => {
      if (
        profileFacebookAPIData ||
        profileInstagramAPIData ||
        profileTwitterAPIData ||
        profileLinkedInAPIData
      ) {
        try {
          console.log();
          const url = "socialaccounts/update";
          const token = localStorage.getItem("web23_token");
          let dataToBeSubmit = {};
          if (profileFacebookAPIData) {
            console.log("profileFacebookAPIData");
            console.log(profileFacebookAPIData.data.first_name);

            dataToBeSubmit = {
              social_account_name: "facebook",
              first_name: profileFacebookAPIData.data.first_name,
              last_name: profileFacebookAPIData.data.last_name,
              full_name: profileFacebookAPIData.data.name,
              userID: profileFacebookAPIData.data.userID,
              email: profileFacebookAPIData.data.email,
            };
          } else if (profileInstagramAPIData) {
            dataToBeSubmit = {
              social_account_name: "instagram",
              first_name: profileInstagramAPIData.data.first_name,
              last_name: profileInstagramAPIData.data.last_name,
              full_name: profileInstagramAPIData.data.name,
              userID: profileInstagramAPIData.data.userID,
              email: profileInstagramAPIData.data.email,
            };
          } else if (profileLinkedInAPIData) {
            dataToBeSubmit = {
              social_account_name: "linkedin",
              first_name: profileLinkedInAPIData.data.first_name,
              last_name: profileLinkedInAPIData.data.last_name,
              full_name: profileLinkedInAPIData.data.name,
              userID: profileLinkedInAPIData.data.userID,
              email: profileLinkedInAPIData.data.email,
            };
          } else if (profileTwitterAPIData) {
            dataToBeSubmit = {
              social_account_name: "twitter",
              first_name: profileTwitterAPIData.data.first_name,
              last_name: profileTwitterAPIData.data.last_name,
              full_name: profileTwitterAPIData.data.name,
              userID: profileTwitterAPIData.data.userID,
              email: profileTwitterAPIData.data.email,
            };
          }

          const response = await getServices(url, token, dataToBeSubmit);
          if (response.data.status == 1) {
            if (profileFacebookAPIData) {
              setProfileFacebook(dataToBeSubmit);
            } else if (profileInstagramAPIData) {
              setProfileInstagram(dataToBeSubmit);
            } else if (profileLinkedInAPIData) {
              setProfileLinkedIn(dataToBeSubmit);
            } else if (profileTwitterAPIData) {
              setProfileTwitter(dataToBeSubmit);
            }
          }
        } catch (error) {
          console.error("Error fetching domains", error);
        }
      }
    };
    updateSocialMediaData();
  }, [
    profileFacebookAPIData,
    profileInstagramAPIData,
    profileTwitterAPIData,
    profileLinkedInAPIData,
  ]);

  const getWhoisParam = (_whoisData, _param) => {
    let tmpData = "";
    let i = _whoisData.indexOf(_param);
    tmpData =
      i >= 0 ? _whoisData.substring(i + _param.length).trim() : _whoisData;
    let nl =
      tmpData.indexOf("\r\n") >= 0
        ? tmpData.indexOf("\r\n")
        : tmpData.indexOf("\n");
    return i >= 0 && nl > 0 ? tmpData.substring(0, nl) : "";
  };

  const fetchDNSDataForSelected = async () => {
    try {
    setDnsRecordForDomain("");
      let domainNameSelected = domainNameSelectedForDNSRecord;
      const token = localStorage.getItem("web23_token");
      const urlnewdns = "action/get/dnsrecords";
      const dnsdata = await getServices(urlnewdns, token, {
        domain: domainNameSelected,
      }); // Use the correct variable
      setDnsRecordForDomain(dnsdata.data.resData);
      setIsLoadedDns(false);
      setLoader(true);
      setDomainsDataRecomanded([]);
      let accumulatedRecommendedData = [];
      let accumulatedRecommendedDataEchrochment = [];
      const urlnew = "gd/domain/search";

      const responseNewAvlDomain = await getServices(urlnew, token, {
        domainName: domainNameSelected,
      }); // Use the correct variable
      const dataForProcess = responseNewAvlDomain.data.resData.domains;
      const filterRecomndedDataAlike = dataForProcess.filter(
        (data) => data.available === true
      );
      const filterRecomndedDataEnchrochment = dataForProcess.filter(
        (data) => data.available === false
      );
      accumulatedRecommendedData = [
        ...accumulatedRecommendedData,
        ...filterRecomndedDataAlike,
      ];
      accumulatedRecommendedDataEchrochment = [
        ...accumulatedRecommendedDataEchrochment,
        ...filterRecomndedDataEnchrochment,
      ];
      setDomainsDataRecomandedEchrochmentCount(
        accumulatedRecommendedDataEchrochment.length
      );
      setDomainsDataRecomandedAlikeCount(accumulatedRecommendedData.length);
      console.log(domainsDataRecomandedAlikeCount);
      setDomainsDataRecomanded(accumulatedRecommendedData);
      setIsLoadedDns(true);
      setLoader(false);
      setIsLoaded(true)
      setDomainNameSelectedForDNSRecord("");
    } catch (error) {
      console.error(error);
      setLoader(false);
      setIsLoadedDns(false);
    }
  };

  useEffect(() => {
    if (domainNameSelectedForDNSRecord) {
      // Check if domainNameSelectedForDNSRecord is not null or undefined
      fetchDNSDataForSelected();
    }
  }, [domainNameSelectedForDNSRecord]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        setLoader(true);
        const url = "domains/getmaindomain2";
        const token = localStorage.getItem("web23_token");
        const response = await getServices(url, token);

        const structuredData = response.data.resData.data.map((domain) => ({
          itemName: domain.domain_name,
          itemId: domain._id,
          _id: domain._id,
          orderType: "D2",
          auto_renew: domain.autorenwel,
          purchaseDate: domain.purchase_date,
          expireOn: domain.domain_exp
            ? domain.domain_exp.split("T")[0].split("-").reverse().join("-")
            : "",
          domain_exp: domain.domain_exp,
        }));

        setDomainNameSelectedForDNSRecord(structuredData[0].itemName);
        fetchDNSDataForSelected();
        setDomainsDataActive(structuredData);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching domains", error);
      }
    };

    const fetchSocialMediaData = async () => {
      try {
        setLoader(true);
        const url = "socialaccounts/get";
        const token = localStorage.getItem("web23_token");
        const response = await getServices(url, token);

        const structuredData = response.data.resData.map((socialMediaData) => {
          if (socialMediaData) {
            if (socialMediaData.social_account_name == "facebook") {
              let dataToBeSubmit = {
                social_account_name: socialMediaData.social_account_name,
                user_name: socialMediaData.username,
                password: socialMediaData.password,
                account_api_response: socialMediaData.account_api_response,
              };
              setProfileFacebook(dataToBeSubmit);
            } else if (socialMediaData.social_account_name == "instagram") {
              let dataToBeSubmit = {
                social_account_name: socialMediaData.social_account_name,
                user_name: socialMediaData.username,
                password: socialMediaData.password,
                account_api_response: socialMediaData.account_api_response,
              };
              setProfileInstagram(dataToBeSubmit);
            } else if (socialMediaData.social_account_name == "twitter") {
              let dataToBeSubmit = {
                social_account_name: socialMediaData.social_account_name,
                user_name: socialMediaData.username,
                password: socialMediaData.password,
                account_api_response: socialMediaData.account_api_response,
              };
              setProfileTwitter(dataToBeSubmit);
            } else if (socialMediaData.social_account_name == "linkedin") {
              let dataToBeSubmit = {
                social_account_name: socialMediaData.social_account_name,
                user_name: socialMediaData.username,
                password: socialMediaData.password,
                account_api_response: socialMediaData.account_api_response,
              };
              setProfileLinkedIn(dataToBeSubmit);
            }
          }
        });
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching domains", error);
      }
    };

    fetchSocialMediaData();
    fetchDomains();

    const socialMediaAlikes = async () => {
      try {
        const token = localStorage.getItem("web23_token");
        const structuredData = [
          { name: "instagram.com" },
          { name: "facebook.com" },
          { name: "twitter.com" },
          { name: "linkedin.com" },
        ];

        let accumulatedSocialMediaAlikeRecommendedData = [];
        for (const domain of structuredData) {
          const urlnew = "gd/domain/search";
          try {
            const responseNewAvlDomain = await getServices(urlnew, token, {
              domainName: domain.name,
            });
            var dataForProcess = responseNewAvlDomain.data.resData.domains;
            const filterRecomndedDataAlike = dataForProcess.filter(
              (data) => data.available === true
            );
            accumulatedSocialMediaAlikeRecommendedData = [
              ...accumulatedSocialMediaAlikeRecommendedData,
              ...filterRecomndedDataAlike,
            ];
          } catch (error) {
            console.error(
              `Error fetching data for domain ${domain.name}:`,
              error
            );
          }
        }

        //   console.log('accumulatedSocialMediaAlikeRecommendedData.length')
        //   console.log(accumulatedSocialMediaAlikeRecommendedData.length)
        setSocialMediaAlikeCount(
          accumulatedSocialMediaAlikeRecommendedData.length
        );
        setIsLoadedSocialAlike(true);
      } catch (error) {
        console.error("Error fetching domains", error);
        setIsLoadedSocialAlike(false);
      }
    };
    socialMediaAlikes();

    const socialMediaAlikesStoredMonthWiseForCurentYear = async () => {
      try {
        const token = localStorage.getItem("web23_token");
        const urlnew = "reports/handleGetAllAlikeSocialMediaCountMonthYearwise";
        try {
          const responseAPI = await getServices(urlnew, token);
          setlooksAlikeSocialMediaYearMonthWise(responseAPI.data.data);

          const totalSocialMediaAlikeCounts = responseAPI.data.data.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
          setlooksAlikeSocialMediaYearMonthWiseTotalCount(
            totalSocialMediaAlikeCounts
          );
          setlooksAlikeSocialMediaYearMonthwiseLoaded(true);
        } catch (error) {
          console.error(
            `Error fetching data for domain socialMediaAlikesStoredMonthWiseForCurentYear:`,
            error
          );
        }
      } catch (error) {
        console.error(
          "Error fetching socialMediaAlikesStoredMonthWiseForCurentYear",
          error
        );
        setlooksAlikeSocialMediaYearMonthwiseLoaded(false);
      }
    };
    socialMediaAlikesStoredMonthWiseForCurentYear();
  }, []);

  const [selectedWeb2, setSelectedWeb2] = useState({
    value: 1,
    label: "Last 7 days",
  });
  const [loader, setLoader] = useState(false);
  const [web2data, setWeb2data] = useState([]);
  const [web2Label, setWeb2Label] = useState([]);
  const [web2Enroachmentsdata, setWeb2Enroachmentsdata] = useState([]);
  const [web2EnroachmentsLabel, setWeb2EnroachmentsLabel] = useState([]);
  const [web2Redirectiondata, setWeb2Redirectiondata] = useState([]);
  const [web2RedirectionLabel, setWeb2RedirectionLabel] = useState([]);
  const [web2Threatdata, setWeb2Threatdata] = useState([]);
  const [web2ThreatLabel, setWeb2ThreatLabel] = useState([]);

  const [selectedWeb3, setSelectedWeb3] = useState({
    value: 1,
    label: "Last 7 days",
  });
  const [web3Loader, setWeb3Loader] = useState(false);
  const [web3data, setWeb3Data] = useState([]);
  const [web3Label, setWeb3Label] = useState([]);
  const [web3Enroachmentsdata, setWeb3Enroachmentsdata] = useState([]);
  const [web3EnroachmentsLabel, setWeb3EnroachmentsLabel] = useState([]);
  const [web3Redirectiondata, setWeb3Redirectiondata] = useState([]);
  const [web3RedirectionLabel, setWeb3RedirectionLabel] = useState([]);
  const [web3Threatdata, setWeb3Threatdata] = useState([]);
  const [web3ThreatLabel, setWeb3ThreatLabel] = useState([]);

  const [dounutData, setDonutData] = useState([]);
  const [web3DonutData, setWeb3DonutData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.type === 1) {
      getWeb2Likes();
      getWeb2Enroachments();
      getWeb2Redirection();
      getWeb2Threats();
    } else {
      getWeb3Likes();
      getWeb3Enroachments();
      getWeb3Redirection();
      getWeb3Threats();
    }
  }, [selectedWeb2, selectedWeb3]);

  /*--- web2 donut data ----*/
  useEffect(() => {
    setDonutData([]);
    if (
      web2data.length > 0 &&
      web2Enroachmentsdata.length > 0 &&
      web2Redirectiondata.length > 0 &&
      web2Threatdata.length > 0
    ) {
      const web2DonutArr = [];
      const web2DataSum = parseInt(
        web2data.reduce((a, b) => a + b, 0) +
          web2Enroachmentsdata.reduce((a, b) => a + b, 0) +
          web2Redirectiondata.reduce((a, b) => a + b, 0) +
          web2Threatdata.reduce((a, b) => a + b, 0)
      );
      const web2LikesDonutTempData =
        (web2data.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
      const web2EnrochmentsDonutTempData =
        (web2Enroachmentsdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
      const web2RedirectionDonutTempData =
        (web2Redirectiondata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
      const web2ThreatDonutTempData =
        (web2Threatdata.reduce((a, b) => a + b, 0) / web2DataSum) * 100;
      web2DonutArr.push(
        Math.round((web2LikesDonutTempData + Number.EPSILON) * 100) / 100,
        Math.round((web2EnrochmentsDonutTempData + Number.EPSILON) * 100) / 100,
        Math.round((web2RedirectionDonutTempData + Number.EPSILON) * 100) / 100,
        Math.round((web2ThreatDonutTempData + Number.EPSILON) * 100) / 100
      );
      setDonutData(web2DonutArr);
    }
  }, [web2data, web2Enroachmentsdata, web2Redirectiondata, web2Threatdata]);

  /*--- web3 donut data ----*/
  useEffect(() => {
    setWeb3DonutData([]);
    if (
      web3data.length > 0 &&
      web3Enroachmentsdata.length > 0 &&
      web3Redirectiondata.length > 0 &&
      web3Threatdata.length > 0
    ) {
      const web3DonutArr = [];
      const web3DataSum = parseInt(
        web3data.reduce((a, b) => a + b, 0) +
          web3Enroachmentsdata.reduce((a, b) => a + b, 0) +
          web3Redirectiondata.reduce((a, b) => a + b, 0) +
          web3Threatdata.reduce((a, b) => a + b, 0)
      );
      const web3LikesDonutTempData =
        (web3data.reduce((a, b) => a + b, 0) / web3DataSum) * 100;
      const web3EnrochmentsDonutTempData =
        (web3Enroachmentsdata.reduce((a, b) => a + b, 0) / web3DataSum) * 100;
      const web3RedirectionDonutTempData =
        (web3Redirectiondata.reduce((a, b) => a + b, 0) / web3DataSum) * 100;
      const web3ThreatDonutTempData =
        (web3Threatdata.reduce((a, b) => a + b, 0) / web3DataSum) * 100;
      web3DonutArr.push(
        Math.round((web3LikesDonutTempData + Number.EPSILON) * 100) / 100,
        Math.round((web3EnrochmentsDonutTempData + Number.EPSILON) * 100) / 100,
        Math.round((web3RedirectionDonutTempData + Number.EPSILON) * 100) / 100,
        Math.round((web3ThreatDonutTempData + Number.EPSILON) * 100) / 100
      );
      setWeb3DonutData(web3DonutArr);
    }
  }, [web3data, web3Enroachmentsdata, web3Redirectiondata, web3Threatdata]);

  const options = [
    {
      value: 1,
      label: "Last 7 days",
    },
    {
      value: 2,
      label: "Last 5 weeks",
    },
    {
      value: 3,
      label: "Last year",
    },
  ];

  /*-----------------------------------------------------------
       ------------------------web2 card details-----------------
       ---------------------------------------------------------*/

  /*--------- get Web2 Likes ---------------*/
  const getWeb2Likes = () => {
    setLoader(true);
    setWeb2data([]);
    setWeb2Label([]);
    var url = "reports/web2/likes/bylast7days";

    if (selectedWeb2.value === 2) {
      var url = "reports/web2/likes/bylast5weeks";
    } else if (selectedWeb2.value === 3) {
      var url = "reports/web2/likes/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb2.value === 1) {
          var temp_data = [];
          var temp_label = [];
          res.resData.map((item) => {
            temp_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_label.push(item.dayName);
          });
          setWeb2data(temp_data);
          setWeb2Label(temp_label);
        } else if (selectedWeb2.value === 3) {
          var temp_data = [];
          var temp_label = [];
          res.resData.map((item) => {
            temp_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_label.push(item.monthName);
          });
          setWeb2data(temp_data);
          setWeb2Label(temp_label);
        } else {
          var temp_data = [];
          var temp_label = [];
          res.resData.map((item) => {
            temp_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_label.push(item.week);
          });
          setWeb2data(temp_data);
          setWeb2Label(temp_label);
        }
        setLoader(false);
      } else {
        setError(res.resData[0].msg);
        setLoader(false);
      }
    });
  };

  /*--------- get Web2 enroachments -------------*/
  const getWeb2Enroachments = () => {
    setLoader(true);
    setWeb2EnroachmentsLabel([]);
    setWeb2Enroachmentsdata([]);
    var url = "rptdata/web2/encroachements/bylast7days";

    if (selectedWeb2.value === 2) {
      var url = "rptdata/web2/encroachements/bylast5weeks";
    } else if (selectedWeb2.value === 3) {
      var url = "rptdata/web2/encroachements/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb2.value === 1) {
          var temp_enroachments_data = [];
          var temp_enroachments_label = [];
          res.resData.map((item) => {
            temp_enroachments_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_enroachments_label.push(item.dayName);
          });
          setWeb2Enroachmentsdata(temp_enroachments_data);
          setWeb2EnroachmentsLabel(temp_enroachments_label);
        } else if (selectedWeb2.value === 3) {
          var temp_enroachments_data = [];
          var temp_enroachments_label = [];
          res.resData.map((item) => {
            temp_enroachments_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_enroachments_label.push(item.monthName);
          });
          setWeb2Enroachmentsdata(temp_enroachments_data);
          setWeb2EnroachmentsLabel(temp_enroachments_label);
        } else {
          var temp_enroachments_data = [];
          var temp_enroachments_label = [];
          res.resData.map((item) => {
            temp_enroachments_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_enroachments_label.push(item.week);
          });
          setWeb2Enroachmentsdata(temp_enroachments_data);
          setWeb2EnroachmentsLabel(temp_enroachments_label);
        }
        setLoader(false);
      } else {
        setError(res.resData[0].msg);
        setLoader(false);
      }
    });
  };

  /* --------- get web2 redirections -------------- */
  const getWeb2Redirection = () => {
    setLoader(true);
    setWeb2RedirectionLabel([]);
    setWeb2Redirectiondata([]);
    var url = "reports/web2/redirection/bylast7days";

    if (selectedWeb2.value === 2) {
      var url = "reports/web2/redirection/bylast5weeks";
    } else if (selectedWeb2.value === 3) {
      var url = "reports/web2/redirection/bylast12months";
    }

    let token = localStorage.getItem("web23_token");

    getServices(url, token).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb2.value === 1) {
          var temp_redirection_data = [];
          var temp_redirection_label = [];
          res.resData.map((item) => {
            temp_redirection_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_redirection_label.push(item.dayName);
          });
          setWeb2Redirectiondata(temp_redirection_data);
          setWeb2RedirectionLabel(temp_redirection_label);
        } else if (selectedWeb2.value === 3) {
          var temp_redirection_data = [];
          var temp_redirection_label = [];
          res.resData.map((item) => {
            temp_redirection_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_redirection_label.push(item.monthName);
          });
          setWeb2Redirectiondata(temp_redirection_data);
          setWeb2RedirectionLabel(temp_redirection_label);
        } else {
          var temp_redirection_data = [];
          var temp_redirection_label = [];
          res.resData.map((item) => {
            temp_redirection_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_redirection_label.push(item.week);
          });
          setWeb2Redirectiondata(temp_redirection_data);
          setWeb2RedirectionLabel(temp_redirection_label);
        }
        setLoader(false);
      } else {
        setError(res.resData[0].msg);
        setLoader(false);
      }
    });
  };

  /* --------- get web2 threats ---------------- */
  const getWeb2Threats = () => {
    setLoader(true);
    setWeb2ThreatLabel([]);
    setWeb2Threatdata([]);
    var url = "reports/web2/threats/bylast7days";

    if (selectedWeb2.value === 2) {
      var url = "reports/web2/threats/bylast5weeks";
    } else if (selectedWeb2.value === 3) {
      var url = "reports/web2/threats/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb2.value === 1) {
          var temp_threat_data = [];
          var temp_threat_label = [];
          res.resData.map((item) => {
            temp_threat_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_threat_label.push(item.dayName);
          });
          setWeb2Threatdata(temp_threat_data);
          setWeb2ThreatLabel(temp_threat_label);
        } else if (selectedWeb2.value === 3) {
          var temp_threat_data = [];
          var temp_threat_label = [];
          res.resData.map((item) => {
            temp_threat_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_threat_label.push(item.monthName);
          });
          setWeb2Threatdata(temp_threat_data);
          setWeb2ThreatLabel(temp_threat_label);
        } else {
          var temp_threat_data = [];
          var temp_threat_label = [];
          res.resData.map((item) => {
            temp_threat_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_threat_label.push(item.week);
          });
          setWeb2Threatdata(temp_threat_data);
          setWeb2ThreatLabel(temp_threat_label);
        }
        setLoader(false);
      } else {
        setError(res.resData[0].msg);
        setLoader(false);
      }
    });
  };

  /*--------------------------------------------------------
    ------------------------web3 card details-----------------
    ---------------------------------------------------------*/

  /*--------- get Web3 Likes ---------------*/
  const getWeb3Likes = () => {
    setWeb3Loader(true);
    setWeb3Data([]);
    setWeb3Label([]);
    var url = "rptdata/web3/likes/bylast7days";

    if (selectedWeb3.value === 2) {
      var url = "rptdata/web3/likes/bylast5weeks";
    } else if (selectedWeb3.value === 3) {
      var url = "rptdata/web3/likes/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb3.value === 1) {
          var temp_data = [];
          var temp_label = [];
          res.resData.map((item) => {
            temp_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_label.push(item.dayName);
          });
          setWeb3Data(temp_data);
          setWeb3Label(temp_label);
        } else if (selectedWeb3.value === 3) {
          var temp_data = [];
          var temp_label = [];
          res.resData.map((item) => {
            temp_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_label.push(item.monthName);
          });
          setWeb3Data(temp_data);
          setWeb3Label(temp_label);
        } else {
          var temp_data = [];
          var temp_label = [];
          res.resData.map((item) => {
            temp_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_label.push(item.week);
          });
          setWeb3Data(temp_data);
          setWeb3Label(temp_label);
        }
        setWeb3Loader(false);
      } else {
        setError(res.resData[0].msg);
        setWeb3Loader(false);
      }
    });
  };

  /*--------- get Web3 enroachments -------------*/
  const getWeb3Enroachments = () => {
    setWeb3Loader(true);
    setWeb3EnroachmentsLabel([]);
    setWeb3Enroachmentsdata([]);
    var url = "rptdata/web3/encroachements/bylast7days";

    if (selectedWeb3.value === 2) {
      var url = "rptdata/web3/encroachements/bylast5weeks";
    } else if (selectedWeb3.value === 3) {
      var url = "rptdata/web3/encroachements/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb3.value === 1) {
          var temp_enroachments_data = [];
          var temp_enroachments_label = [];
          res.resData.map((item) => {
            temp_enroachments_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_enroachments_label.push(item.dayName);
          });
          setWeb3Enroachmentsdata(temp_enroachments_data);
          setWeb3EnroachmentsLabel(temp_enroachments_label);
        } else if (selectedWeb3.value === 3) {
          var temp_enroachments_data = [];
          var temp_enroachments_label = [];
          res.resData.map((item) => {
            temp_enroachments_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_enroachments_label.push(item.monthName);
          });
          setWeb3Enroachmentsdata(temp_enroachments_data);
          setWeb3EnroachmentsLabel(temp_enroachments_label);
        } else {
          var temp_enroachments_data = [];
          var temp_enroachments_label = [];
          res.resData.map((item) => {
            temp_enroachments_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_enroachments_label.push(item.week);
          });
          setWeb3Enroachmentsdata(temp_enroachments_data);
          setWeb3EnroachmentsLabel(temp_enroachments_label);
        }
        setWeb3Loader(false);
      } else {
        setError(res.resData[0].msg);
        setWeb3Loader(false);
      }
    });
  };

  /* --------- get web3 redirections ------------- */
  const getWeb3Redirection = () => {
    setWeb3Loader(true);
    setWeb3RedirectionLabel([]);
    setWeb3Redirectiondata([]);
    var url = "rptdata/web3/redirection/bylast7days";

    if (selectedWeb3.value === 2) {
      var url = "rptdata/web3/redirection/bylast5weeks";
    } else if (selectedWeb3.value === 3) {
      var url = "rptdata/web3/redirection/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb3.value === 1) {
          var temp_redirection_data = [];
          var temp_redirection_label = [];
          res.resData.map((item) => {
            temp_redirection_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_redirection_label.push(item.dayName);
          });
          setWeb3Redirectiondata(temp_redirection_data);
          setWeb3RedirectionLabel(temp_redirection_label);
        } else if (selectedWeb3.value === 3) {
          var temp_redirection_data = [];
          var temp_redirection_label = [];
          res.resData.map((item) => {
            temp_redirection_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_redirection_label.push(item.monthName);
          });
          setWeb3Redirectiondata(temp_redirection_data);
          setWeb3RedirectionLabel(temp_redirection_label);
        } else {
          var temp_redirection_data = [];
          var temp_redirection_label = [];
          res.resData.map((item) => {
            temp_redirection_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_redirection_label.push(item.week);
          });
          setWeb3Redirectiondata(temp_redirection_data);
          setWeb3RedirectionLabel(temp_redirection_label);
        }
        setWeb3Loader(false);
      } else {
        setError(res.resData[0].msg);
        setWeb3Loader(false);
      }
    });
  };

  /* --------- get web3 threats ---------------- */
  const getWeb3Threats = () => {
    setWeb3Loader(true);
    setWeb3ThreatLabel([]);
    setWeb3Threatdata([]);
    var url = "rptdata/web3/threats/bylast7days";

    if (selectedWeb3.value === 2) {
      var url = "rptdata/web3/threats/bylast5weeks";
    } else if (selectedWeb3.value === 3) {
      var url = "rptdata/web3/threats/bylast12months";
    }

    var data = {
      token: localStorage.getItem("web23_token"),
    };

    services(true, url, data).then((response) => {
      let res = response.data;

      if (res.status === 1) {
        if (selectedWeb3.value === 1) {
          var temp_threat_data = [];
          var temp_threat_label = [];
          res.resData.map((item) => {
            temp_threat_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_threat_label.push(item.dayName);
          });
          setWeb3Threatdata(temp_threat_data);
          setWeb3ThreatLabel(temp_threat_label);
        } else if (selectedWeb3.value === 3) {
          var temp_threat_data = [];
          var temp_threat_label = [];
          res.resData.map((item) => {
            temp_threat_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_threat_label.push(item.monthName);
          });
          setWeb3Threatdata(temp_threat_data);
          setWeb3ThreatLabel(temp_threat_label);
        } else {
          var temp_threat_data = [];
          var temp_threat_label = [];
          res.resData.map((item) => {
            temp_threat_data.push(item.value);
          });
          res.resData.map((item) => {
            temp_threat_label.push(item.week);
          });
          setWeb3Threatdata(temp_threat_data);
          setWeb3ThreatLabel(temp_threat_label);
        }
        setWeb3Loader(false);
      } else {
        setError(res.resData[0].msg);
        setWeb3Loader(false);
      }
    });
  };

  return (
    <div>
      <div className={`${styles.e_report_wrapper}`}>
        {props.type !== 4 ? (
          <>
            <div className="container-fluid e-px-32 e-py-32 e-border-bottom">
              <div className="row">
                <div className="col-md-7">
                  <div className="d-md-flex d-none align-items-center">
                    <h3 className="pe-2 mb-0 main_heading">{props.title}</h3>
                    {/* <HeathIndicatorBadge healthy={props.issues ? false : true} value={props.issues} /> */}
                    <div
                      className={`${styles.e_select_wrapper} d-flex align-items-center ms-4`}
                    >
                      <span className={styles.e_select_indicator}>Show:</span>
                      <div className={`${styles.e_select_container} ms-1`}>
                        {props.type === 1 ? (
                          <SingleSelect
                            defaultValue={selectedWeb2}
                            options={options}
                            placeholder="This month"
                            selectchange={(temp) => setSelectedWeb2(temp)}
                          />
                        ) : (
                          <SingleSelect
                            defaultValue={selectedWeb3}
                            options={options}
                            placeholder="This month"
                            selectchange={(temp) => setSelectedWeb3(temp)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex d-md-none flex-column w-100">
                    <div className="d-flex w-100">
                      <h3 className="pe-2 mb-0">{props.title}</h3>
                      <HeathIndicatorBadge
                        healthy={props.issues ? false : true}
                        value={props.issues}
                      />
                    </div>
                    <div
                      className={`${styles.e_select_wrapper} d-flex align-items-center pt-3 justify-content-end w-100`}
                    >
                      <span className={styles.e_select_indicator}>Show:</span>
                      <div className={`${styles.e_select_container} ms-1`}>
                        <SingleSelect placeholder="This month" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 pt-md-0 pt-2">
                  <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                    <TextButton
                      name={`Manage ${props.title} Domains`}
                      className="mx-3 social_media_ac_sidetitle"
                      forward={true}
                      handleClick={props.redirect}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid e-px-32 e-py-32">
              <div className="row gy-3">
                <div className="col-lg-3">
                  <div className={`${styles.e_charts_container} e-donut-chart`}>
                    <span
                      className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}
                    >
                      {props.title} summary
                    </span>
                    {props.type === 1 ? (
                      loader ? (
                        <Loader />
                      ) : dounutData.length > 0 ? (
                        <PieChart
                          data={dounutData}
                          labels={[
                            "Look Alikes",
                            "Total Encroachments",
                            "Provided Redirections",
                            "Resolved Threats",
                          ]}
                          colors={["#FF5322", "#1874FF", "#ECE200", "#19BFE3"]}
                        />
                      ) : null
                    ) : props.type === 2 ? (
                      web3Loader ? (
                        <Loader />
                      ) : web3DonutData.length > 0 ? (
                        <PieChart
                          data={web3DonutData}
                          labels={[
                            "Look Alikes",
                            "Total Encroachments",
                            "Provided Redirections",
                            "Resolved Threats",
                          ]}
                          colors={["#FF5322", "#1874FF", "#ECE200", "#19BFE3"]}
                        />
                      ) : null
                    ) : (
                      <>
                        {isLoadedSocialAlike && (
                          <PieChart
                            data={[socialMediaAlikeCount]}
                            labels={["Look Alikes"]}
                            colors={["#ECE200", "#19BFE3"]}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div
                        className={`${styles.e_charts_container} e-line-chart`}
                      >
                        {props.type === 1 ? (
                          loader ? (
                            <Loader />
                          ) : web2data.length > 0 && web2Label.length > 0 ? (
                            <>
                              <p>Look Alikes</p>
                              <span className={styles.e_count_indicator}>
                                {web2data.length > 0
                                  ? web2data.reduce((a, b) => a + b, 0)
                                  : "31"}
                              </span>
                              <LineChart
                                color="#FFE176"
                                data={[
                                  {
                                    name: "Look Alikes",
                                    data: web2data,
                                  },
                                ]}
                                width="400"
                                categories={web2Label}
                              />
                            </>
                          ) : null
                        ) : props.type === 2 ? (
                          web3Loader ? (
                            <Loader />
                          ) : web3data.length > 0 && web3Label.length > 0 ? (
                            <>
                              <p>Look Alikes</p>
                              <span className={styles.e_count_indicator}>
                                {web3data.length > 0
                                  ? web3data.reduce((a, b) => a + b, 0)
                                  : "31"}
                              </span>
                              <LineChart
                                color="#FFE176"
                                data={[
                                  {
                                    name: "Look Alikes",
                                    data: web3data,
                                  },
                                ]}
                                width="400"
                                categories={web3Label}
                              />
                            </>
                          ) : null
                        ) : (
                          <>
                            {looksAlikeSocialMediaLoadedYearMonthWise && (
                              <>
                                <p>Look Alikes</p>
                                <span className={styles.e_count_indicator}>
                                  {looksAlikeSocialMediaYearMonthWiseTotalCount}
                                </span>

                                <LineChart
                                  color="#FFE176"
                                  data={[
                                    {
                                      name: "Look Alikes",
                                      data: looksAlikeSocialMediaYearMonthWise,
                                    },
                                  ]}
                                  width="400"
                                  categories={[
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                  ]}
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {props.type !== 3 ? (
                      <>
                        <div className="col-lg-6">
                          <div
                            className={`${styles.e_charts_container} e-line-chart`}
                          >
                            {props.type === 1 ? (
                              loader ? (
                                <Loader />
                              ) : web2Enroachmentsdata.length > 0 &&
                                web2EnroachmentsLabel.length > 0 ? (
                                <>
                                  <p>Total Encroachments</p>
                                  <span className={styles.e_count_indicator}>
                                    {web2Enroachmentsdata.length > 0
                                      ? web2Enroachmentsdata.reduce(
                                          (a, b) => a + b,
                                          0
                                        )
                                      : "90"}
                                  </span>
                                  <LineChart
                                    color="#FF5322"
                                    data={[
                                      {
                                        name: "Total Encroachments",
                                        data: web2Enroachmentsdata,
                                      },
                                    ]}
                                    width="400"
                                    categories={web2EnroachmentsLabel}
                                  />
                                </>
                              ) : null
                            ) : web3Loader ? (
                              <Loader />
                            ) : web3Enroachmentsdata.length > 0 &&
                              web3EnroachmentsLabel.length > 0 ? (
                              <>
                                <p>Total Encroachments</p>
                                <span className={styles.e_count_indicator}>
                                  {web3Enroachmentsdata.length > 0
                                    ? web3Enroachmentsdata.reduce(
                                        (a, b) => a + b,
                                        0
                                      )
                                    : null}
                                </span>
                                <LineChart
                                  color="#FF5322"
                                  data={[
                                    {
                                      name: "Total Encroachments",
                                      data: web3Enroachmentsdata,
                                    },
                                  ]}
                                  width="400"
                                  categories={web3EnroachmentsLabel}
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className={`${styles.e_charts_container} e-line-chart`}
                          >
                            {props.type === 1 ? (
                              loader ? (
                                <Loader />
                              ) : web2RedirectionLabel.length > 0 &&
                                web2Redirectiondata.length > 0 ? (
                                <>
                                  <p>Provided Redirections</p>
                                  <span className={styles.e_count_indicator}>
                                    {web2Redirectiondata.reduce(
                                      (prev, last) => prev + last,
                                      0
                                    )}
                                  </span>
                                  <LineChart
                                    color="#19BFE3"
                                    data={[
                                      {
                                        name: "Provided Redirections",
                                        data: web2Redirectiondata,
                                      },
                                    ]}
                                    width="400"
                                    categories={web2RedirectionLabel}
                                  />
                                </>
                              ) : null
                            ) : web3Loader ? (
                              <Loader />
                            ) : web3Redirectiondata.length > 0 &&
                              web3RedirectionLabel.length > 0 ? (
                              <>
                                <p>Provided Redirections</p>
                                <span className={styles.e_count_indicator}>
                                  {web3Redirectiondata.reduce(
                                    (prev, last) => prev + last,
                                    0
                                  )}
                                </span>
                                <LineChart
                                  color="#19BFE3"
                                  data={[
                                    {
                                      name: "Provided Redirections",
                                      data: web3Redirectiondata,
                                    },
                                  ]}
                                  width="400"
                                  categories={web3RedirectionLabel}
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className={`${styles.e_charts_container} e-line-chart`}
                          >
                            {props.type === 1 ? (
                              loader ? (
                                <Loader />
                              ) : web2Threatdata.length > 0 &&
                                web2ThreatLabel.length > 0 ? (
                                <>
                                  <p>Resolved Threats</p>
                                  <span className={styles.e_count_indicator}>
                                    {web2Threatdata.reduce(
                                      (prev, last) => prev + last,
                                      0
                                    )}
                                  </span>
                                  <LineChart
                                    color="#1874FF"
                                    data={[
                                      {
                                        name: "Resolved Threats",
                                        data: web2Threatdata,
                                      },
                                    ]}
                                    width="400"
                                    categories={web2ThreatLabel}
                                  />
                                </>
                              ) : null
                            ) : web3Loader ? (
                              <Loader />
                            ) : web3Threatdata.length > 0 &&
                              web3ThreatLabel.length > 0 ? (
                              <>
                                <p>Resolved Threats</p>
                                <span className={styles.e_count_indicator}>
                                  {web3Threatdata.reduce(
                                    (prev, last) => prev + last,
                                    0
                                  )}
                                </span>
                                <LineChart
                                  color="#1874FF"
                                  data={[
                                    {
                                      name: "Resolved Threats",
                                      data: web3Threatdata,
                                    },
                                  ]}
                                  width="400"
                                  categories={web3ThreatLabel}
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-12">
                          <div className=" e-border-bottom w-100"></div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`${styles.e_charts_container} socialmedia_dash_card_shadow`}
                          >
                            <img
                              src={Facebook}
                              alt="Facebook Icon"
                              className={styles.e_media_icon}
                            />
                            <div className="d-flex w-100 pt-2 justify-content-between align-items-center">
                              <h4 className="fw-normal mb-0">Facebook</h4>

                              {profileFacebook ? (
                                <Primary
                                  name="Disconnect"
                                  handleClick={() =>
                                    openModal("Facebook Login")
                                  }
                                />
                              ) : (
                                <LoginSocialFacebook
                                  appId={facebookAppId}
                                  onResolve={(response) => {
                                    setProfileFacebookAPIData(response);
                                  }}
                                  onReject={(error) => {
                                    // console.log(error);
                                  }}
                                >
                                  <Primary name="Connect" />
                                </LoginSocialFacebook>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`${styles.e_charts_container} socialmedia_dash_card_shadow`}
                          >
                            <img
                              src={Twitter}
                              alt="Twitter Icon"
                              className={styles.e_media_icon}
                            />
                            <div className="d-flex w-100 pt-2 justify-content-between align-items-center">
                              <h4 className="fw-normal mb-0">Twitter</h4>

                              {profileTwitter ? (
                                <Primary
                                  name="Disconnect"
                                  handleClick={() => openModal("Twitter Login")}
                                />
                              ) : (
                                <LoginSocialTwitter
                                  appId={twitterAppId}
                                  onResolve={(response) => {
                                    setprofileTwitterAPIData(response);
                                  }}
                                  onReject={(error) => {
                                    // console.log(error);
                                  }}
                                >
                                  {/* <TwitterLoginButton /> */}
                                  <Primary name="Connect" />
                                </LoginSocialTwitter>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`${styles.e_charts_container} socialmedia_dash_card_shadow`}
                          >
                            <img
                              src={Linkedin}
                              alt="Linkedin Icon"
                              className={styles.e_media_icon}
                            />
                            <div className="d-flex w-100 pt-2 justify-content-between align-items-center">
                              <h4 className="fw-normal mb-0">Linkedin</h4>

                              {profileLinkedIn ? (
                                <Primary
                                  name="Disconnect"
                                  handleClick={() =>
                                    openModal("LinkedIn Login")
                                  }
                                />
                              ) : (
                                <LoginSocialLinkedin
                                  appId={linkedinAppId}
                                  onResolve={(response) => {
                                    setProfileLinkedInAPIData(response);
                                  }}
                                  onReject={(error) => {
                                    // console.log(error);
                                  }}
                                >
                                  <Primary name="Connect" />
                                </LoginSocialLinkedin>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`${styles.e_charts_container} socialmedia_dash_card_shadow`}
                          >
                            <img
                              src={Instagram}
                              alt="Instagram Icon"
                              className={styles.e_media_icon}
                            />
                            <div className="d-flex w-100 pt-2 justify-content-between align-items-center">
                              <h4 className="fw-normal mb-0">Instagram</h4>
                              {profileInstagram ? (
                                <Primary
                                  name="Disconnect"
                                  handleClick={() =>
                                    openModal("Instagram Login")
                                  }
                                />
                              ) : (
                                <LoginSocialInstagram
                                  appId={instagramAppId}
                                  onResolve={(response) => {
                                    setProfileInstagramAPIData(response);
                                  }}
                                  onReject={(error) => {
                                    // console.log(error);
                                  }}
                                >
                                  <Primary name="Connect" />
                                </LoginSocialInstagram>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container-fluid e-px-32 e-py-32 e-border-bottom">
              <div className="row">
                <div className="col-md-7">
                  <div className="d-flex align-items-center">
                    <h3 className="pe-2 mb-0">{props.title}</h3>
                  </div>
                </div>
                <div className="col-md-5 pt-md-0 pt-2">
                  <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                    <TextButton
                      name={`Manage ${props.title}`}
                      className="mx-3"
                      forward={true}
                      handleClick={props.redirect}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid e-px-32 e-py-32">
              <div className="row gy-3">
                <div className="col-lg-3">
                  <div className={`${styles.e_charts_container} e-donut-chart`}>
                    <span
                      className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}
                    >
                      {props.title} summary
                    </span>
                    {isLoaded && (
                      <PieChart
                        data={
                          props.type == 4
                            ? [
                                domainsDataRecomandedAlikeCount,
                                dataRecomandedEchrochmentCount,
                                0,
                                0,
                              ]
                            : [10]
                        }
                        labels={
                          props.type == 4
                            ? [
                                "Look Alikes",
                                "Total Encroachments",
                                "Provided Redirections",
                                "Resolved Threats",
                              ]
                            : ["Not Set"]
                        }
                        colors={
                          props.type != 3
                            ? ["#FF5322", "#1874FF", "#ECE200", "#19BFE3"]
                            : ["#FF18B2", "#19BFE3"]
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className={`${styles.e_charts_container} p-0`}>
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <div className="d-flex flex-column">
                          {/* <div className={selected === 1 ? `${styles.e_template_head} d-flex align-items-center justify-content-between p-3 ${styles.e_template_selected}` : `${styles.e_template_head} d-flex align-items-center justify-content-between p-3`} >
                                                            <div className="d-flex align-items-center">
                                                                <h4 className="mb-0 pe-2">sarahjames.com</h4>
                                                                <img src={Warning} alt="Warning Icon" />
                                                            </div>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.0938 10L8.08458 4.9917L6.90625 6.17003L10.7396 10.0034L6.90625 13.8309L8.08458 15.0092L13.0938 10Z" fill="#2E3A59" />
                                                            </svg>
                                                        </div>
                                                        <div className={selected === 2 ? `${styles.e_template_head} d-flex align-items-center justify-content-between p-3 ${styles.e_template_selected}` : `${styles.e_template_head} d-flex align-items-center justify-content-between p-3`} >
                                                            <div className="d-flex align-items-center">
                                                                <h4 className="mb-0 pe-2">sarahjames.xyz</h4>
                                                                <img src={Success} alt="Success Icon" />
                                                            </div>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.0938 10L8.08458 4.9917L6.90625 6.17003L10.7396 10.0034L6.90625 13.8309L8.08458 15.0092L13.0938 10Z" fill="#2E3A59" />
                                                            </svg>
                                                        </div>
                                                        <div className={selected === 3 ? `${styles.e_template_head} d-flex align-items-center justify-content-between p-3 ${styles.e_template_selected}` : `${styles.e_template_head} d-flex align-items-center justify-content-between p-3`} >
                                                            <div className="d-flex align-items-center">
                                                                <h4 className="mb-0 pe-2">sarahjames.xyz</h4>
                                                                <img src={Success} alt="Success Icon" />
                                                            </div>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.0938 10L8.08458 4.9917L6.90625 6.17003L10.7396 10.0034L6.90625 13.8309L8.08458 15.0092L13.0938 10Z" fill="#2E3A59" />
                                                            </svg>
                                                        </div> */}
                          {domainsDataActive.map((domain, index) => (
                            <div
                              key={domain._id}
                              className={
                                selected === index
                                  ? `${styles.e_template_head} d-flex align-items-center justify-content-between p-3 ${styles.e_template_selected}`
                                  : `${styles.e_template_head} d-flex align-items-center justify-content-between p-3`
                              }
                              onClick={() => {
                                setDomainNameSelectedForDNSRecord(
                                  domain.itemName
                                ); // Set the selected domain name
                                // setSelected(domain.itemName); // Update the selected item
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <h4 className="mb-0 pe-2">{domain.itemName}</h4>
                                {/* <img src={index % 2 === 0 ? Warning : Success} alt={index % 2 === 0 ? "Warning Icon" : "Success Icon"} /> */}
                              </div>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.0938 10L8.08458 4.9917L6.90625 6.17003L10.7396 10.0034L6.90625 13.8309L8.08458 15.0092L13.0938 10Z"
                                  fill="#2E3A59"
                                />
                              </svg>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className="col-6 col-md-6 custom-scroll"
                        style={{
                          maxHeight: "400px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        {dnsRecordForDomain ? (
                          <div>
                            <h3 className="mt-2 mb-2 main_heading">
                              {domainNameSelectedForDNSRecord} DNS Details
                            </h3>
                            <div className="table-responsive">
                              <table
                                className="table table-bordered"
                                width="99%"
                                cellPadding="10"
                                cellSpacing="0"
                                border="1"
                              >
                                <thead>
                                  <tr>
                                    <th className="f14 fd">Type</th>
                                    <th className="f14 fd">Name</th>
                                    <th className="f14 fd">Value</th>
                                    <th className="f14 fd">TTL</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Handle A records */}
                                  {dnsRecordForDomain.A?.map(
                                    (record, index) => (
                                      <tr key={`A-${index}`}>
                                        <td className="f12">A</td>
                                        <td className="f12">@</td>
                                        <td className="f12">
                                          {record.address}
                                        </td>
                                        <td className="f12">{record.ttl}</td>
                                      </tr>
                                    )
                                  )}

                                  {/* Handle NS records */}
                                  {dnsRecordForDomain.NS?.map((ns, index) => (
                                    <tr key={`NS-${index}`}>
                                      <td className="f12">NS</td>
                                      <td className="f12">@</td>
                                      <td className="f12">{ns}</td>
                                      <td className="f12">-</td>
                                    </tr>
                                  ))}

                                  {/* Handle SOA record */}
                                  {dnsRecordForDomain.SOA && (
                                    <tr>
                                      <td className="f12">SOA</td>
                                      <td className="f12">@</td>
                                      <td className="f12">
                                        nsname: {dnsRecordForDomain.SOA.nsname},
                                        hostmaster:{" "}
                                        {dnsRecordForDomain.SOA.hostmaster},
                                        serial: {dnsRecordForDomain.SOA.serial},
                                        refresh:{" "}
                                        {dnsRecordForDomain.SOA.refresh}, retry:{" "}
                                        {dnsRecordForDomain.SOA.retry}, expire:{" "}
                                        {dnsRecordForDomain.SOA.expire}, minttl:{" "}
                                        {dnsRecordForDomain.SOA.minttl}
                                      </td>
                                      <td className="f12">-</td>
                                    </tr>
                                  )}

                                  {/* Handle TXT records */}
                                  {dnsRecordForDomain.TXT?.map(
                                    (txtRecords, index) => (
                                      <tr key={`TXT-${index}`}>
                                        <td className="f12">TXT</td>
                                        <td className="f12">@</td>
                                        <td className="f12">
                                          {txtRecords.join(", ")}
                                        </td>
                                        <td className="f12">-</td>
                                      </tr>
                                    )
                                  )}

                                  {/* Handle MX and CNAME if they exist */}
                                  {dnsRecordForDomain.MX && (
                                    <tr>
                                      <td className="f12">MX</td>
                                      <td className="f12">@</td>
                                      <td className="f12">
                                        {dnsRecordForDomain.MX}
                                      </td>
                                      <td className="f12">-</td>
                                    </tr>
                                  )}

                                  {dnsRecordForDomain.CNAME && (
                                    <tr>
                                      <td className="f12">CNAME</td>
                                      <td className="f12">@</td>
                                      <td className="f12">
                                        {dnsRecordForDomain.CNAME}
                                      </td>
                                      <td className="f12">-</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div>No DNS records found.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {error !== null ? <Toast data={error} /> : null}
    </div>
  );
};

export default withRouter(DashboardReport);
