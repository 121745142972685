import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { CartContext } from '../../context/cartContext';
import services from '../../services/apis/services';
import styles from '../../styles/components/SearchTable.module.scss';
import { Primary } from '../cta';
import { Toast } from '../alerts';

const EncroachmentTable = (props) => {
    const [cartData, setCartData] = useContext(CartContext);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [cartItem, setCartItem] = useState("");
    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Items per page, change if needed

    const totalPages = Math.ceil(props.data?.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = props.data?.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    return (
        <div className='mt-4 py-4'>
            <div className="">
                <div className="">
                    <table className="table table-hover table-striped table-bordered table-box-sha">
                        <thead>
                            <tr>
                                <td width='60px'><h6>Sr. No.</h6></td> 
                                <td width='320px'><h6>Domain Name</h6></td>
                                <td><h6>IP(s)</h6></td>
                                <td><h6>Navigate</h6></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems && currentItems.length > 0 ? (
                                currentItems.map((item, i) => (
                                    <tr key={`search-result-row-${i}`}>
                                        <td>{indexOfFirstItem + i + 1}</td>
                                        <td className="text-break">
                                            <div className="w-100 d-flex flex-column">
                                                <div className="fs-5 mb-2 text-lowercase text-break">{item.domain}</div>
                                            </div>
                                        </td>
                                        <td className="r899">
                                            <span className="d-flex h-100">
                                                {(item.ips != null && item.ips.length >= 1) && 
                                                    item.ips.join(" , ")
                                                }
                                                {(item.ips === null || item.ips.length === 0) &&
                                                    <span>-</span>
                                                }
                                            </span>
                                        </td>
                                        <td className="y788">
                                            <div className="d-flex">
                                                {(item.ips != null && item.ips.length >= 1) && (
                                                    <a href={"https://" + item.domain} target='_blank' rel="noreferrer">Goto site</a>
                                                )}
                                                {(item.ips === null || item.ips.length === 0) && (
                                                    <span className='fs-6 fw-light text-decoration-line-through'>Parked</span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No Data Available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
                <button 
                    className="btn btn-primary"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>

                <span>Page {currentPage} of {totalPages}</span>

                <button 
                    className="btn btn-primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            {error && <Toast data={error} type="fail" />}
        </div>
    );
}

export default EncroachmentTable;
