import React, { useRef, useState, useEffect } from 'react';
import styles from '../../styles/components/DomainCard.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Success from '../../assets/images/icons/tick-icon-18.svg';
import Warning from '../../assets/images/icons/warning-icon-18.svg';
import PlusIcon from '../../assets/images/icons/plus-icon-20.png';
import { Primary, TextButton } from '../../../src/components/cta';
import moment from 'moment';
import { getServices } from "../../services/apis/services";

import { Toast } from '../alerts';

const DomainCard = (props) => {
    // console.log("props carddddddd");
    // console.log(props);
    let webType = props.web23;
    // console.log(webType);
    

    const textAreaRef = useRef(null);
    const textAreaRefCnameValue = useRef(null);
    const [generatedString, setGeneratedString] = useState(''); // Store only the random string
    const [generatedStringCnameValue, setGeneratedStringCnameValue] = useState(''); // Store only the random string
    const [generatedText, setGeneratedText] = useState(''); // Store the full text including domain
    const [formData, setFormData] = useState({}); // State object to store all form inputs
    const [loader, setLoader] = useState(false);
    
    const [domainAddVerified, setDomainAddVerified] = useState(false); // Set the domain
    // Function to generate a random string between 10 and 60 characters
    const generateRandomString = (minLength, maxLength) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    const handleShowModal = (e) => {
        setDomainAddVerified(false)
        e.stopPropagation();
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
        modal.show();
    };

    // Function to handle the modal's show event and update the textarea
    const handleModalShow = () => {
        const randomString = generateRandomString(10, 60);
        const randomStringCNameValue = generateRandomString(10, 60);
        setGeneratedString(randomString);
        setGeneratedStringCnameValue(randomStringCNameValue); // (randomStringCNameValue+"."); Initially set generatedText to only the random string
    };

    // const handleCopyText = () => {
    //     if (textAreaRef.current) {
    //         textAreaRef.current.select();
    //         document.execCommand('copy');
    //         alert('Text copied to clipboard!');
    //     }
    // };

    const [toastMessage, setToastMessage] = useState(null); // State for toast message

    const handleCopyText = () => {
        if (textAreaRef.current) {
            textAreaRef.current.select();
            document.execCommand('copy');
            setToastMessage({ message: 'Text copied to clipboard!', type: 'success' }); 
        }
    };

    
    const handleCopyTextCnameValue = () => {
        if (textAreaRefCnameValue.current) {
            textAreaRefCnameValue.current.select();
            document.execCommand('copy');
            // alert('Text copied to clipboard!');
            setToastMessage({ message: 'Text copied to clipboard!', type: 'success' }); 
        }
    };

    useEffect(() => {
        // Attach event listener for Bootstrap modal show event
        const modalElement = document.getElementById('exampleModal');
        modalElement.addEventListener('show.bs.modal', handleModalShow);

        // Cleanup event listener on component unmount
        return () => {
            modalElement.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, []);

    const addDomain = async () => {
        try {

            setLoader(true);

            let url = "";
            if (props.web23 == 'web2') {
                url = "domains/add2";
                
            } else if (props.web23 == 'web3') {
                 url = "domains/add3";
                 
            }

            const token = localStorage.getItem("web23_token");
            const response = await getServices(url, token, formData);
            if (response.data.success == true ) {
                // alert("Domain added to the list");
                
                // const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
                // modal.hide();

                 // Correct way to hide modal
                const modalElement = document.getElementById('exampleModal');
                const modalInstance = window.bootstrap.Modal.getInstance(modalElement); // Retrieve the current modal instance
                if (modalInstance) {

                    setGeneratedString('')
                    setGeneratedStringCnameValue('')
                    setGeneratedText('')
                    setFormData('')

                    modalInstance.hide(); // Hide the modal
                }
                setLoader(false);
                setToastMessage({ message: 'Domain added to the list', type: 'success' });
            } else {
                setLoader(false);
                setToastMessage({ message: response.data.error+" "+ response.data.details, type: 'error' });

            }
        } catch (error) {
            setLoader(false);
            setToastMessage({ message: 'An error occurred during submit.', type: 'error' });
        }
    };

    const handleDomainAdd = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            addDomain();
        }
    };

    const handleDomainVerify= async (e) => {
        e.preventDefault();
        if (validateForm()) {
            verifyDomain();
        }
    };

    const verifyDomain = async () => {
        
        try {
            setDomainAddVerified(false)
            // console.log("props checkkkk");
            // console.log(props);
            setLoader(true);

            let url = "";
            if (props.web23 == 'web2') {
                 url = "domains/verifycnamedomain2";
            } else if (props.web23 == 'web3') {
                 url = "domains/verifycnamedomain3";
            }
            
            const token = localStorage.getItem("web23_token");
            const response = await getServices(url, token, formData);
            if (response.data.domainValid == true) {
                setDomainAddVerified(true)
                // alert(response.data.messege)
                setToastMessage({ message: 'Domain has been verified. You can now submit.', type: 'success' });
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            setToastMessage({ message: 'An error occurred during verification.', type: 'error' });
            console.error("Error fetching domains", error);
        }
    };


    // Generic handler to update form fields in state
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let sanitizedValue = value.replace(/^https?:\/\//, '').replace(/\/$/, '');

        setFormData({
            ...formData,
            [name]: sanitizedValue,
        });

        // setFormData(prevState => ({
        //     ...prevState,
        //     [name]: value
        // }));


       

        setFormData(prevState => ({
            ...prevState,
            ['cname_value']: generatedStringCnameValue
        }));

        // Update the generated text when the domain name changes
        if (name === 'domain_name') {
            let domain_name = `${generatedString}.${value}${"."}`;
            // Perform the replace operation and assign the result back to domain_name
            domain_name = domain_name.replace(/https?:\/\//, '').replace(/\//g, '');
            setGeneratedText(domain_name);
        
            setFormData(prevState => ({
                ...prevState,
                ['cname_key']: domain_name // Use the modified domain_name here as well
            }));
            
        }
        
    };

    const [errors, setErrors] = useState({
        domainName: '',
        cname: '',
        cnameValue: '',
    });
    

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};
    
        // Validate domain name
        if (!formData.domain_name) {
            errors.domainName = 'Domain Name is required';
            formIsValid = false;
        } else if (!/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.domain_name)) {
            errors.domainName = 'Invalid domain name format';
            formIsValid = false;
        }
    
        // Validate Cname
        if (!generatedText) {
            errors.cname = 'Cname is required';
            formIsValid = false;
        }
    
        // Validate Cname Value
        if (!generatedStringCnameValue) {
            errors.cnameValue = 'Cname Value is required';
            formIsValid = false;
        }
    
        setErrors(errors);
        return formIsValid;
    };
    


    return (
        <>
        <div>
            <div 
                className={props.data ? styles.e_card_wrapper : `${styles.e_new_domain_wrapper} ${process.env.REACT_APP_VISIBLE_WEB_3_0 === 'true' ? styles.e_card_wrapper : ''} position-relative`} 
                onClick={props.data ? props.redirect : null}
            >
            {/* <div 
                className={`${styles.e_card_wrapper} ${!props.data && styles.e_new_domain_wrapper} 
                            ${!props.data && 'position-relative'}
                            ${process.env.REACT_APP_VISIBLE_WEB_3_0 === 'true' ? styles.e_card_wrapper : ''}`}
                onClick={props.data ? props.redirect : null}
            > */}
                {props.data ? ( 
                    <>
                        <div className="d-flex align-items-center">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 15C2.5 21.9036 8.09644 27.5 15 27.5C21.9004 27.4924 27.4924 21.9004 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15ZM5.07737 13.75C5.47278 10.579 7.35284 7.86912 10 6.33782L10 13.75H5.07737ZM12.5 13.75V5.31504C13.299 5.10938 14.1368 5 15 5C15.8633 5.00097 16.701 5.11119 17.5 5.31759V13.75H12.5ZM17.5 16.25H12.5V24.685C13.299 24.8906 14.1368 25 15 25C15.8632 25 16.7009 24.8906 17.5 24.685V16.25ZM20 23.6622V16.25H24.9226C24.5272 19.421 22.6472 22.1309 20 23.6622ZM20 13.75V6.34258C22.6444 7.87458 24.523 10.5819 24.9213 13.75H20ZM5.07737 16.25H10L10 23.6622C7.35284 22.1309 5.47278 19.421 5.07737 16.25ZM25 15.0108L25 15L25 14.9957V15.0108Z" fill="url(#paint0_linear_906:12670)" />
                                <defs>
                                    <linearGradient id="paint0_linear_906:12670" x1="15" y1="2.5" x2="15" y2="27.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FFE176" />
                                        <stop offset="1" stopColor="#ECE200" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <h3 className="ps-2 mb-0 text-lowercase">{props.data.itemName}</h3>
                        </div>
                        <div className="d-flex align-items-center pt-4">
                            <p className="mb-0 pe-2">Status: <strong>{props.expact ==='act' ? "Active" : "Inactive"} </strong></p>
                            <img src={props.expact ==='act' ? Success : Warning} alt="Status Icon" />
                        </div>
                        <p className="mb-0 pe-2 pt-3">Auto Renew: <strong>{props.data.autorenwel ? "On" : "Off"}</strong></p>
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <p className="mb-0 pe-2 pt-3">Expires on: <strong>{props.data.expireOn}</strong></p>
                            <TextButton name="" forward={true} handleClick={props.redirect} />
                        </div>
                    </>
                ) : (
                    <>
                    {process.env.REACT_APP_VISIBLE_WEB_3_0 =='true'  &&
                        <div className={`${styles.e_hidden_content}`}>
                            <div className="d-flex align-items-center">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 15C2.5 21.9036 8.09644 27.5 15 27.5C21.9004 27.4924 27.4924 21.9004 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15ZM5.07737 13.75C5.47278 10.579 7.35284 7.86912 10 6.33782L10 13.75H5.07737ZM12.5 13.75V5.31504C13.299 5.10938 14.1368 5 15 5C15.8633 5.00097 16.701 5.11119 17.5 5.31759V13.75H12.5ZM17.5 16.25H12.5V24.685C13.299 24.8906 14.1368 25 15 25C15.8632 25 16.7009 24.8906 17.5 24.685V16.25ZM20 23.6622V16.25H24.9226C24.5272 19.421 22.6472 22.1309 20 23.6622ZM20 13.75V6.34258C22.6444 7.87458 24.523 10.5819 24.9213 13.75H20ZM5.07737 16.25H10L10 23.6622C7.35284 22.1309 5.47278 19.421 5.07737 16.25ZM25 15.0108L25 15L25 14.9957V15.0108Z" fill="url(#paint0_linear_906:12670)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_906:12670" x1="15" y1="2.5" x2="15" y2="27.5" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FFE176" />
                                            <stop offset="1" stopColor="#ECE200" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <h3 className="ps-2 mb-0 text-lowercase">Domain.com</h3>
                            </div>
                            <div className="d-flex align-items-center pt-4">
                                <p className="mb-0 pe-2">Status: <strong>Active</strong></p>
                                <img src={Success} alt="Status Icon" />
                            </div>
                            <p className="mb-0 pe-2 pt-3">Auto Renew: <strong>On</strong></p>
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <p className="mb-0 pe-2 pt-3">Expires on: <strong></strong></p>
                                <TextButton name="" forward={true} />
                            </div>
                        </div>}
                        {process.env.REACT_APP_VISIBLE_WEB_3_0 === 'true' &&
                        <div 
                            className={`${styles.e_new_domain_container} w-100 h-100 position-absolute d-flex align-items-center flex-column justify-content-center`} 
                            onClick={handleShowModal}
                        >
                            <div className={styles.e_plus_icon_wrapper}>
                                <img src={PlusIcon} alt="+" />
                            </div>
                            <h5 className="mb-0 pt-3">
                                Add New Domain
                            </h5>
                        </div>}
                    </>
                )}
            </div>

            {/* Bootstrap Modal */}
            <div className="modal fade" id="exampleModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content my-domain-model">
                        <div className="modal-header">
                            <h5 className="modal-title mb-4" id="exampleModalLabel">Add New Domain</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <h6 style={{color: "red"}}>Please add cname and cvalue to the domain account, after that it will required upto 30second to 1 minute to reflect in DNS record and then click on <b>Verify</b> </h6>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="domainName" className="form-label">Domain Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="domain_name" 
                                        name="domain_name" 
                                        value={formData.domain_name || ''} 
                                        onChange={handleInputChange} 
                                        required 
                                    />
                                    {errors.domainName && <span className="text-danger">{errors.domainName}</span>}
                                </div>
                                <div className="mb-3 position-relative">
                                    <label htmlFor="details" className="form-label">Cname</label>
                                    <textarea
                                        className="form-control"
                                        id="txt_data" 
                                        name="txt_data"
                                        rows="3"
                                        ref={textAreaRef}
                                        value={generatedText} // Set the generated text as the value
                                        readOnly
                                        required
                                    ></textarea>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="position-absolute end-0 top-0 mt-2 me-2"
                                        onClick={handleCopyText}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    {errors.cname && <span className="text-danger">{errors.cname}</span>}
                                        
                                </div>
                                <div className="mb-3 position-relative">
                                    <label htmlFor="details" className="form-label">Cname Value</label>
                                    <textarea
                                        className="form-control"
                                        id="txt_data" 
                                        name="txt_data"
                                        rows="3"
                                        ref={textAreaRefCnameValue}
                                        value={generatedStringCnameValue} // Set the generated text as the value
                                        readOnly
                                        required
                                    ></textarea>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="position-absolute end-0 top-0 mt-2 me-2"
                                        onClick={handleCopyTextCnameValue}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    {errors.cnameValue && <span className="text-danger">{errors.cnameValue}</span>}
                                </div>
                                
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                 <>
                                 { domainAddVerified ? <Primary value="Submit" name="Submit" className={loader ? "e-btn-loader" : ""} handleClick={handleDomainAdd}>Submit</Primary> 
                                 :
                                    <Primary value="Verify" name="Verify" className={loader ? "e-btn-loader" : ""} handleClick={handleDomainVerify}>Verify</Primary>
                                 }
                                    </>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
                {toastMessage && <Toast data={toastMessage.message} type={toastMessage.type} />}

                {toastMessage && setTimeout(() => setToastMessage(null), 4000)}
        </div>
        </>
    );
};

export default DomainCard;
