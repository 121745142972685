import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

/* Components */
import { Footer, Header } from './components/navigation';
import { Tooltip } from './components/alerts/index';

/* Containers */
import Dashboard from './container/dashboard/dashboard';
import Test from './container/test/test';
import AuthContainer from './container/auth/auth';
import SearchDomain from './container/search/search';
import CartContainer from './container/cart/cart';
import CheckoutContainer from './container/checkout/checkout';
import ManageDomain from './container/domains/manageDomain';
import SocialMedia from './container/socialMedia/socialMedia';
import DomainDetail from './container/domainDetail/domainDetail';
import Reports from './container/reports/reports';
import DomainManagement from './container/domainManagement/domainManagement';
import ProfileContainer from './container/profile/profileContainer';
import ManageCoupons from './container/coupons/manageCoupons';
import CouponList from './container/coupons/couponList';
import TemplatesContainer from './container/templates/templates';
import ChangePassword from './components/auth/changePassword';
import PrivacyPolicy from './container/privacy_policy/privacyPolicy';
import ContactUs from './container/contactus/contactUs';
import Faq from './container/faq/faq';
import TermsAndConditions from './container/termsAndCondition/termsAndCondition';

/* Contexts */
import { CartDataProvider } from './context/cartContext';
import { HandlerDataProvider } from './context/tooltipHandlerContext';
import services from './services/apis/services';
import Cookies from 'js-cookie';

import CreateAccountB2B from './components/auth/createAccountB2B';

function App() {
  const [cart, setCart] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Check if token exists and is valid
  const checkAuthentication = () => {
    const token = Cookies.get('web23_token');
    return !!token; // Return true if token exists
  };

  useEffect(() => {
    // Simulate token validation on app load
    const tokenValid = checkAuthentication();
    if (tokenValid) {
      setIsAuthenticated(true);
      getProfileDetails();
    } else {
      setIsAuthenticated(false);
    }
    
    setIsNewUser(!!Cookies.get('web23_user')); 
    setIsLoading(false); // Done checking, stop the loading state
  }, []);

  const getProfileDetails = () => {
    const SESSION_TOKEN = Cookies.get('web23_token');
    if (!SESSION_TOKEN) {
      return;
    }

    const url = 'action/profile/get';
    const data = { token: SESSION_TOKEN };

    services(true, url, data).then((response) => {
      const res = response.data;
      if (res.status === 1) {
        setProfileData(res.resData);
      }
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <HandlerDataProvider>
        <CartDataProvider>
          <Switch>
            {/* Route for public paths that should be outside the authentication logic */}
            <Route path="/b2b" component={CreateAccountB2B} exact />
            <Route path="/search/:type" render={({ match }) => (<SearchDomain type={match.params.type} />)} exact />
            <Route path="/search" render={({ location }) => (<Redirect to={`/search/web2${location.search}`} />)} exact />
            
            {/* Authenticated and non-new user */}
            {isAuthenticated && !isNewUser ? (
              <>
                <Header cart={(data) => setCart(data)} profile={profileData} />
                <div className="e-app-body-wrapper">
                  <Switch>
                    <Route path="/" exact>
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route path="/dashboard" component={Dashboard} exact />
                    <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
                    <Route path="/ContactUs" component={ContactUs} exact />
                    <Route path="/Faq" component={Faq} exact />
                    <Route path="/TermsAndConditions" component={TermsAndConditions} exact />
                    <Route path="/test" component={Test} exact />
                    <Route path="/domain/:type/detail" exact render={({ match }) => (<DomainDetail type={match.params.type} />)} />
                    <Route path="/domain/:type/manage" exact render={({ match }) => (<DomainManagement type={match.params.type} />)} />
                    <Route path="/domains/:type" exact render={({ match }) => (<ManageDomain type={match.params.type} />)} />
                    <Route path="/domains" component={ManageDomain} exact />
                    
                    <Route path="/social-media" component={SocialMedia} exact />
                    <Route path="/reports" component={Reports} exact />
                    <Route path="/cart" render={() => <CartContainer cart={cart} />} exact />
                    <Route path="/cart/checkout" component={CheckoutContainer} exact />
                    <Route path="/profile/:type" component={ProfileContainer} exact />
                    <Route path="/profile" exact>
                      <Redirect to="/profile/orders" />
                    </Route>
                    <Route path="/manage-coupons/:type" exact render={({ match }) => (<ManageCoupons type={match.params.type} />)} />
                    <Route path="/manage-coupons" exact>
                      <Redirect to="/manage-coupons/web2" />
                    </Route>
                    <Route path="/templates/:type" exact render={({ match }) => (<TemplatesContainer type={match.params.type} />)} />
                    <Route path="/templates" exact>
                      <Redirect to="/templates/web2" />
                    </Route>
                    <Route path="/coupons" exact component={CouponList} />
                    <Redirect to="/dashboard" />
                  </Switch>
                </div>
                <Footer />
              </>
            ) : 
            // If new user, force password change
            isNewUser ? (
              <>
                <Switch>
              <Route path="/change-password" component={ChangePassword} exact />
              <Redirect to="/change-password" />
              </Switch>
              </>
            ) : (
              // Not authenticated, show login
              <>
                <Switch>
                  <Route path="/auth/:type" render={({ match }) => (<AuthContainer type={match.params.type} />)} />
                  <Redirect to="/auth/login" />
                </Switch>
              </>
            )}
          </Switch>
          <Tooltip />
        </CartDataProvider>
      </HandlerDataProvider>
    </BrowserRouter>
  );
}

export default App;
