import React, { useState } from "react";
import styles from '../../styles/components/ExpiringSoon.module.scss';
import { Primary } from "../cta";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

const ExpiringSoon = (props) => {
    const [currentPage, setCurrentPage] = useState(1); // State to track current page
    const itemsPerPage = 5; // Number of items per page

    // Calculate the total number of pages
    const totalPages = Math.ceil(props.data.length / itemsPerPage);

    // Get the current items based on pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = props.data.slice(startIndex, startIndex + itemsPerPage);

    // Handle page change
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={styles.e_card_wrapper}>
            <h3 className="mb-0 main_heading">Expiring Soon</h3>
            <div className={`${styles.e_table_wrapper} w-100 pt-3`}>
                <div className={`${styles.e_table_container} py-3`}>
                    <div className="table-responsive">
                        {currentItems && currentItems.length ? (
                            
                            <table className="table table-hover table-striped table-bordered">
                                <thead className={`${styles.e_table_head}`}>
                                    <tr>
                                    <td width='60px'><h6>Sr. No.</h6></td>
                                    <th scope="col">Domain Name</th>
                                    <th scope="col">Expiry on</th>
                                    <th scope="col">Auto Renewal</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody className={`${styles.e_table_body}`}>
                                    {currentItems.map((item, i) => (
                                        <tr key={i}>
                                        <td>{startIndex + i + 1}</td>
                                        <td className="text-lowercase align-middle">
                                        <span style={styles.badge} className="text-capitalize self_fs-5">{item.name}</span>
                                        </td>
                                        <td className="align-middle">
                                            <p className="mb-0">{item.expiry}</p>
                                        </td>
                                        <td className="align-middle">
                                            <p className={item.autorenwel ? "e-green-text fw-bold mb-0" : "e-warning-color fw-bold mb-0"}>
                                            {item.autorenwel ? "On" : "Off"}
                                            </p>
                                        </td>
                                        <td className="align-middle">
                                            {item.autorenwel ? (
                                            <Primary
                                                grey={true}
                                                disabled={true}
                                                name={
                                                <span className={`${styles.e_auto_renew_btn_content} d-flex align-items-center flex-column`}>
                                                    <span className="fw-normal">Will renew on</span>
                                                    <span className="fw-bold">{item.expiry}</span>
                                                </span>
                                                }
                                            />
                                            ) : (
                                            <Primary name="Renew" />
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        
                    ) : (
                        // <h3 className="d-flex justify-content-center">🎉 Congratulations! Till now, none of your domains are expiring soon! 🎉</h3>
                        <h3 className="d-flex justify-content-center align-items-center noexpsoon py-1">
                            <div className="text-center">
                                <FontAwesomeIcon icon={faClipboardCheck} size="2x" className="text-success me-3 mb-2" />
                                <h3 className="h4">Till now, none of your domains are expiring soon!</h3>
                            </div>
                        </h3>
                    )}
                    </div>
                </div>
                


                {/* Pagination Controls */}
                {currentItems && currentItems.length > 0 && (
                    <div className="pagination-controls d-flex justify-content-center mt-4">
                        <button
                            className="btn expiting_soon_pre_btn mx-2"
                            onClick={() => goToPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span className="mx-2 mt-2">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            className="btn expiting_soon_nxt_btn mx-2"
                            onClick={() => goToPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpiringSoon;
