/*--------------
prop-type        card-type
1                Web2 Records Card
2                Web2 Nameserver Card
3                Web3 Records Card
---------------*/

import React, { useEffect, useState } from "react";

/*import style*/
import styles from '../../styles/components/RecordsCard.module.scss';
import services from '../../services/apis/services';
import { Primary, TextButton } from '../cta';
import { SingleSelect } from '../form';
import EditW2RecordModal from '../modals/editW2RecordModal';
import EditNSRecordModal from '../modals/editNSRecordModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import Swal from "sweetalert2";


const sampleData = [

    { type: "A", name: "@", value: "192.165.445.256", ttl: "300 sec" },
    { type: "CNAME", name: "www", value: "@", ttl: "1 hr" },
    { type: "CNAME", name: "w23connect", value: "w23connect.w23.domaincontrol.com", ttl: "1 hr" },
    { type: "Nameserver", name: "@", value: "dns12.domaincontrol.com", ttl: "1 hr" },
    { type: "Nameserver", name: "@", value: "dns13.domaincontrol.com", ttl: "1 hr" },
    { type: "SOA", name: "@", value: "dns13.domaincontrol.com", ttl: "1 hr" }
]

const sampleNameServers = [
    { nameserver: "dns12.domaincontrol.com" },
    { nameserver: "dns13.domaincontrol.com" },
]

const w3Address = [
    { type: "BTC", address: "0xe7f5be6cb3bf6f95f79f850947b5a656ca22a29" },
    { type: "ETH", address: "" },
    { type: "LTC", address: "" },
    { type: "DOGE", address: "" },
]

const w3TextRecords = [
    { type: "Twitter", value: "@sarah_james" },
    { type: "Facebook", value: "" },
    { type: "Profile Image", value: "https://imgur.com/yu.jpg" },
    { type: "Email", value: "" },
]

const RecordsCard = (props) => {
	const [reqested,setRequested]=useState(false);
	const [domainName,setDomainName]=useState("");
	let [recordA,setRecordA]=useState([]);
	let [recordAAAA,setRecordAAAA]=useState([]);
	let [recordNS,setRecordNS]=useState([]);
	let [recordSOA,setRecordSOA]=useState([]);
	let [recordCNAME,setRecordCNAME]=useState([]);
	let [recordMX,setRecordMX]=useState([]);
	let [recordTXT,setRecordTXT]=useState([]);
	let [records,setRecords]=useState([]);
	let [selectedW2Record,setSelectedW2Record]=useState({});
	let [selectedNSRecord,setSelectedNSRecord]=useState({});
	
	useEffect(() => {
       if(reqested===false && props.domain){
		//    console.log("ItemName:"+props.domain);
		   setDomainName(props.domain);
		   setRequested(true);
		   loadDNS(props.domain);
	   }
    }, [props.domain]);
	
	const loadDNS=(_domainName)=>{
		setRecords([]);
		let _url = 'dns/get/records';
		let _form={domain:_domainName};
		// console.log("Calling GET DOAINS");
		services(true, _url, _form).then((response) => {
			// console.log("Calling GET DOAINS RESULT:"+response);
            let res = response.data;
			if(res.status===1){
				setRecords(res.resData);
			}
		})
		.catch(function (response) {
		console.log(response);
		});
		
		let dnsObj=new DNSRecord();
		dnsObj.loadNS(_domainName);
		
	}
	class DNSRecord {
		loadA(_domainName){
			let SESSION_TOKEN=localStorage.getItem("web23_token");
				let url = 'action/get/dnsrecord';
				let data = {
				  token:SESSION_TOKEN ,
				  domain:_domainName,
				  type:'A'
				};
				// console.log(data);
				services(true, url, data).then((response) => {
				  let res = response.data
				//   console.log("DNS RECORD-A");
				//   console.log(res);
				  if (res.status === 1) {
					  let recs=[];
					  for(let i=0;i<res.resData.length;i++){
						  recs.push({type:"A",name:"@",value:res.resData[i].address,ttl:res.resData[i].ttl});
					  }
					setRecordA(recs);
				  }
				  else setRecordA([]);
				});
		}
		loadTXT(_domainName){
			let SESSION_TOKEN=localStorage.getItem("web23_token");
				let url = 'action/get/dnsrecord';
				let data = {
				  token:SESSION_TOKEN ,
				  domain:_domainName,
				  type:'TXT'
				};
				// console.log(data);
				services(true, url, data).then((response) => {
				  let res = response.data
				//   console.log("DNS RECORD-TXT");
				//   console.log(res);
				  if (res.status === 1) {
					  let recs=[];
					  for(let i=0;i<res.resData.length;i++){
						  recs.push({type:"TXT",name:"",value:res.resData[i][0],ttl:"-"});
					  }
					setRecordTXT(recs);
				  }
				  else setRecordTXT([]);
				});
		}
		loadMX(_domainName){
			let SESSION_TOKEN=localStorage.getItem("web23_token");
				let url = 'action/get/dnsrecord';
				let data = {
				  token:SESSION_TOKEN ,
				  domain:_domainName,
				  type:'MX'
				};
				// console.log(data);
				services(true, url, data).then((response) => {
				  let res = response.data
				//   console.log("DNS RECORD-TXT");
				//   console.log(res);
				  if (res.status === 1) {
					  let recs=[];
					  for(let i=0;i<res.resData.length;i++){
						  recs.push({type:"MX",name:"Target",value:res.resData[i].exchange,ttl:"-"});
					  }
					setRecordMX(recs);
				  }
				  else setRecordMX([]);
				});
		}
		loadAAAA(_domainName){
			let SESSION_TOKEN=localStorage.getItem("web23_token");
				let url = 'action/get/dnsrecord';
				let data = {
				  token:SESSION_TOKEN ,
				  domain:_domainName,
				  type:'AAAA'
				};
				// console.log(data);
				services(true, url, data).then((response) => {
				  let res = response.data
				//   console.log("DNS RECORD-TXT");
				//   console.log(res);
				  if (res.status === 1) {
					  let recs=[];
					  for(let i=0;i<res.resData.length;i++){
						  recs.push({type:"AAAA",name:"@",value:res.resData[i].address,ttl:res.resData[i].ttl});
					  }
					setRecordAAAA(recs);
				  }
				  else setRecordAAAA([]);
				});
		}
		loadNS(_domainName){
			let SESSION_TOKEN=localStorage.getItem("web23_token");
				let url = 'action/get/dnsrecord';
				let data = {
				  token:SESSION_TOKEN ,
				  domain:_domainName,
				  type:'NS'
				};
				// console.log(data);
				services(true, url, data).then((response) => {
				  let res = response.data
				//   console.log("DNS RECORD-NS");
				//   console.log(res);
				  if (res.status === 1) {
					  let recs=[];
					  for(let i=0;i<res.resData.length;i++){
						  recs.push({type:"NS",name:"",value:res.resData[i],ttl:"-"});
					  }
					setRecordNS(recs);
				  }
				  else setRecordNS([]);
				});
		}
		loadSOA(_domainName){
			return;
			let SESSION_TOKEN=localStorage.getItem("web23_token");
				let url = 'action/get/dnsrecord';
				let data = {
				  token:SESSION_TOKEN ,
				  domain:_domainName,
				  type:'SOA'
				};
				// console.log(data);
				services(true, url, data).then((response) => {
				  let res = response.data
				//   console.log("DNS RECORD-SOA");
				//   console.log(res);
				  if (res.status === 1) {
					  let recs=[];
					  for(let i=0;i<res.resData.length;i++){
						  recs.push({type:"SOA",name:"",value:res.resData,ttl:"-"});
					  }
					setRecordSOA(recs);
				  }
				  else setRecordSOA([]);
				});
		}
	}
	const openEditNSRecordModal=(rec)=>{
		// alert(domainName);
		setSelectedNSRecord({
							 name:"@",
							 value:rec.value,
							 type:"NS",
							 ttl:3600});
		
	}
	const openEditW2RecordModal=(rec)=>{
	 
	  setSelectedW2Record(rec);
	//   console.log(rec); 
	}

	const deleteW2Record=(rec)=>{
		let SESSION_TOKEN=localStorage.getItem("web23_token");
		let _form={
			
			token:SESSION_TOKEN ,
			id:rec.slno,
			domain:rec.domain,
			type:rec.type,
			name:rec.name,
			value:rec.value,
			ttl:rec.ttl
			
		};
		let _option={
					'headers': {
					  'method': 'post',
					  'Accept': 'application/json',
					  'Content-Type': 'application/json',
					}
			};
		let _url = 'dns/off/record';
		services(true, _url, _form).then((response) => {
            let res = response.data;
			// console.log(res);
		})
		.catch(function (response) {
		// console.log(response);
		})
		return(false);
	}

	// const deleteW2Record = (rec) => {
	// 	Swal.fire({
	// 		title: 'Are you sure?',
	// 		text: `You won't be able to revert this!`,
	// 		icon: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonColor: '#3085d6',
	// 		cancelButtonColor: '#d33',
	// 		confirmButtonText: 'Yes, delete it!'
	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 			let SESSION_TOKEN = localStorage.getItem("web23_token");
	// 			let _form = {
	// 				token: SESSION_TOKEN,
	// 				id: rec.slno,
	// 				domain: rec.domain,
	// 				type: rec.type,
	// 				name: rec.name,
	// 				value: rec.value,
	// 				ttl: rec.ttl
	// 			};
	// 			let _url = 'dns/off/record';

	// 			services(true, _url, _form)
	// 				.then((response) => {
	// 					let res = response.data;
	// 					if (res.status === 1) {
	// 						// Update the records state to remove the deleted record
	// 						setRecords(prevRecords => prevRecords.filter(record => record.slno !== rec.slno));

	// 						// Show success message
	// 						Swal.fire(
	// 							'Deleted!',
	// 							'Your record has been deleted.',
	// 							'success'
	// 						);
	// 					} else {
	// 						Swal.fire(
	// 							'Error!',
	// 							'Could not delete the record.',
	// 							'error'
	// 						);
	// 					}
	// 				})
	// 				.catch((error) => {
	// 					Swal.fire(
	// 						'Error!',
	// 						'There was a problem deleting your record.',
	// 						'error'
	// 					);
	// 				});
	// 		}
	// 	});
	// };
    
    return (
        <div className={styles.e_card_wrapper}>
            {
                /* Web2 Records Card*/
                props.type === 1 ?
                    <>
                        <div className="w-100 e-px-32 e-py-32 e-border-bottom">
                            <div className="row">
                                <div className="col">
                                    <div className="w-100 h-100 d-flex flex-column flex-md-row align-items-md-center">
                                        <h3 className="mb-md-0">Web 2.0 Records</h3>
                                        <span className={styles.e_last_updated + " ps-md-3 "}>Last update on Aug 15 2020  12.10 PM</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="w-100 h-100 d-flex flex-column flex-md-row align-items-md-center align-items-end justify-content-md-end justify-content-center">
                                        <Primary name="Add" target="#addW2RecordModal" toggle="modal"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 e-px-32 e-py-32">

                            {/* <div className={`${styles.e_table_wrapper}`}>
                                <div className={`${styles.e_table_container} px-3`}>
                                    <div className={`${styles.e_table_head} row gx-1 py-3`}>
                                        <div className="col-2"><h4>Type</h4></div>
                                        <div className="col-3"><h4>Name</h4></div>
                                        <div className="col-4"><h4>Value</h4></div>
                                        <div className="col-2"><h4>Time To Live (TTL)</h4></div>
                                        <div className="col-1"></div>
                                    </div>
                                    <div className={`${styles.e_table_body} row`}>
                                        {
                                            records.map((item, i) => {
                                                return (
                                                    <div className="col-12" key={`table-row-${i}`}>
                                                        <div className={i < sampleData.length - 1 ? "w-100 py-3 e-border-bottom" : "w-100 py-3"}>
                                                            <div className="row">
                                                                <div className="col-2">{item.type}</div>
                                                                <div className="col-3">{item.name}</div>
                                                                <div className="col-4">{item.value}</div>
                                                                <div className="col-1">{item.ttl}</div>
                                                                <div className="col-2">
																	<a  href='#editW2RecordModal' data-bs-toggle="modal"  className='btn px-4' onClick={()=>openEditW2RecordModal(item)}>Edit</a>
																	<a  className='btn pe-4' onClick={()=>{deleteW2Record(item);}}>Delete</a>
																</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
										
                                    </div>
                                </div>
                            </div> */}

                            <div className={`${styles.e_table_wrapper}`}>
                                <div className={`${styles.e_table_container} px-3 py-3 mt-3`}>
                                    <table className="table table-hover table-striped table-bordered">
                                        <thead className={`${styles.e_table_head}`}>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Value</th>
                                                <th scope="col">Time To Live (TTL)</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className={`${styles.e_table_body}`}>
                                            {
                                                records.map((item, i) => (
                                                    <tr key={`table-row-${i}`}>
                                                        <td>{item.type}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.value}</td>
                                                        <td>{item.ttl}</td>
                                                        <td>
                                                            <button 
                                                                className='btn btn-link text-primary p-0 me-2' 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#editW2RecordModal" // Ensure the target ID matches your modal ID
                                                                onClick={() => openEditW2RecordModal(item)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            &nbsp;/ &nbsp;&nbsp;
                                                            <button 
                                                                 className='btn btn-link text-danger p-0' 
                                                                 onClick={() => { deleteW2Record(item); }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </>
                    :
                    /* Web2 Nameservers Card*/
                    props.type === 2 ?
                        <>
                            <div className="w-100 e-px-32 e-py-32 e-border-bottom">
                                <div className="row">
                                    <div className="col">
                                        <div className="w-100 h-100 d-flex flex-column flex-md-row align-items-md-center">
                                            <h3 className="mb-md-0">Nameservers</h3>
                                            <span className={styles.e_last_updated + " ps-md-3 "}>Last update on Aug 15 2020  12.10 PM</span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="w-100 h-100 d-flex flex-column flex-md-row-reverse flex-md-row align-items-md-center align-items-end justify-content-center justify-content-md-start ">
                                            <Primary name="Change" />
                                            <span className={styles.e_info + " pe-md-3 pt-1 pt-md-0"}>Using default nameservers</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 e-px-32 e-py-32">

                                <div className={`${styles.e_table_wrapper}`}>
                                    <div className={`${styles.e_table_container} px-3`}>
                                        <div className={`${styles.e_table_head} row gx-1 py-3`}>
                                            <div className="col-12"><h4>Nameserver</h4></div>
                                        </div>
                                        <div className={`${styles.e_table_body} row`}>
                                            {
                                                recordNS.map((item, i) => {
                                                    return (
                                                        <div className="col-12" key={`table-row-${i}`}>
                                                            <div className={i < sampleNameServers.length - 1 ? "w-100 py-3 e-border-bottom" : "w-100 py-3"}>
                                                                <div className="row">
                                                                    <div className="col-2">{item.value}</div>
																	<div className="col-2">
																	<a  href='#editNSRecordModal' data-bs-toggle="modal"  className='px-4' onClick={()=>openEditNSRecordModal(item)}>Edit</a>
																	</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        props.type === 3 ?
                            /* Web3 Records Card*/
                            <>
                                <div className="w-100 e-px-32 e-py-32 e-border-bottom">
                                    <div className="row gy-3">
                                        <div className="col-sm">
                                            <div className="w-100 h-100 d-flex flex-column flex-lg-row align-items-lg-center">
                                                <h3 className="mb-lg-0">Records</h3>
                                                <div className={`${styles.e_select_wrapper} d-flex align-items-center ms-lg-3 w-100`}>
                                                    <span className={styles.e_select_indicator}>Type:</span>
                                                    <div className={`${styles.e_select_container} ms-1 w-100`}>
                                                        <SingleSelect placeholder="All Types" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="w-100 h-100 d-flex flex-column flex-md-row align-items-md-center align-items-end justify-content-md-end justify-content-center">
                                                <Primary name="Add" target="#addRecordModal" toggle="modal"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 e-px-32 e-py-32">

                                    <div className={`${styles.e_table_wrapper}`}>
                                        <div className={`${styles.e_table_container} px-3`}>
                                            <div className={`${styles.e_table_head} row gx-1 py-3`}>
                                                <div className="col-2"><h4>Addresses</h4></div>
                                                <div className="col-8"></div>
                                                <div className="col-2"></div>
                                            </div>
                                            <div className={`${styles.e_table_body} row`}>
                                                {
                                                    w3Address.map((item, i) => {
                                                        return (
                                                            <div className="col-12" key={`table-row-${i}`}>
                                                                <div className={i < w3Address.length - 1 ? "w-100 py-3 e-border-bottom" : "w-100 py-3"}>
                                                                    <div className="row">
                                                                        <div className="col-2">{item.type}</div>
                                                                        {
                                                                            item.address && item.address.length ?
                                                                                <div className="col-8">{item.address}</div>
                                                                                :
                                                                                <div className="col-8 e-text-muted">Not Added Yet</div>
                                                                        }
                                                                        <div className="col-2"><TextButton name={item.address && item.address.length ? "Edit" : "Add"} blue={true} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.e_table_wrapper} mt-4`}>
                                        <div className={`${styles.e_table_container} px-3`}>
                                            <div className={`${styles.e_table_head} row gx-1 py-3`}>
                                                <div className="col-2"><h4>Content</h4></div>
                                                <div className="col-8"></div>
                                                <div className="col-2"></div>
                                            </div>
                                            <div className={`${styles.e_table_body} row`}>
                                                <div className="col-12">
                                                    <div className={"w-100 py-3 d-flex justify-content-center e-text-muted"}>
                                                        Nothing Added Yet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.e_table_wrapper} mt-4`}>
                                        <div className={`${styles.e_table_container} px-3`}>
                                            <div className={`${styles.e_table_head} row gx-1 py-3`}>
                                                <div className="col-2"><h4>Text Records</h4></div>
                                                <div className="col-8"></div>
                                                <div className="col-2"></div>
                                            </div>
                                            <div className={`${styles.e_table_body} row`}>
                                                {
                                                    w3TextRecords.map((item, i) => {
                                                        return (
                                                            <div className="col-12" key={`table-row-${i}`}>
                                                                <div className={i < w3TextRecords.length - 1 ? "w-100 py-3 e-border-bottom" : "w-100 py-3"}>
                                                                    <div className="row">
                                                                        <div className="col-2">{item.type}</div>
                                                                        {
                                                                            item.value && item.value.length ?
                                                                                <div className="col-8">{item.value}</div>
                                                                                :
                                                                                <div className="col-8 e-text-muted">Not Added Yet</div>
                                                                        }
                                                                        <div className="col-2"><TextButton name={item.value && item.value.length ? "Edit" : "Add"} blue={true} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.e_table_wrapper} mt-4`}>
                                        <div className={`${styles.e_table_container} px-3`}>
                                            <div className={`${styles.e_table_head} row gx-1 py-3`}>
                                                <div className="col-2"><h4>Other Addresses</h4></div>
                                                <div className="col-8"></div>
                                                <div className="col-2"></div>
                                            </div>
                                            <div className={`${styles.e_table_body} row`}>
                                                <div className="col-12">
                                                    <div className={"w-100 py-3 d-flex justify-content-center e-text-muted"}>
                                                        Nothing Added Yet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                            :
                            null
            }
		<EditW2RecordModal	record={selectedW2Record}/>
		<EditNSRecordModal	record={selectedNSRecord} domain={domainName}/>
        </div>
    )
}

export default RecordsCard;