/* Packages */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from "react-router-dom";

/* Components */
import { Primary, TextButton } from '../cta';
import { CheckBox, InputBox, NumberInput } from '../form';
// import { SocialButton } from '../socialLogin';
import { Toast } from '../alerts';

/* Services */
import EmailValidation from '../../services/validation/email';
import PhoneValidation from '../../services/validation/phone';

/* Services */
import services from '../../services/apis/services';

const CreateAccount = (props) => {
    const router = useHistory();
    const [error, setError] = useState(null);
    const [signupLoader, setSignUpLoader] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);


    /*--- signup api integration ---*/
    const SignUp = () => {
        setSignUpLoader(true);

        let url = 'action/add/signup';

        let data = {
            fullName: props.name,
            email: props.email,
            countryCode: props.countryCode,
            mobileNo: props.number,
            password: props.password,
            cpassword: props.confirmPassword
        }
        // console.log("data", data);
        
        services(true, url, data).then((response) => {
            let res = response;
            
            if (res.status === 1) {
                props.setRedirect('create-account');
                router.push('/auth/verify-otp', { email: props.email, token: res.resData.otpToken });
                setSignUpLoader(false);
            } else {
                // setError(res.resData[0].msg);
                
                setSignUpLoader(false);
            }
        })
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        const emailValid = await EmailValidation(props.email);
        const phoneValid = await PhoneValidation("+"+props.countryCode+""+props.number); 
        const passwordValid = props.password.length > 7;
        const passwordSame = props.password === props.confirmPassword;
        const nameValid = props.name.length > 1;
        if (emailValid && phoneValid && passwordValid && passwordSame && nameValid && agreeTerms) {
            SignUp();
        }
        else {
            if (!emailValid) setError('Kindly fill valid email addredd.');
            else if (!phoneValid) setError('Kindly fill correct phone no.');
            else if (!passwordSame) setError('Passwords should be the same.');
            else if (!passwordValid) setError('Password should atleast be 8 characters long');
            else if (!nameValid) setError('Name should atleast be 2 characters long');
            else if (!agreeTerms) setError('You must agree to the terms and conditions.');
        }
    }

    return (
        <div className="container-fluid p-0">
            <div className="e-auth-container w-100">
                <h3 className="text-center mb-3">Sign Up</h3>
                <div className="w-100 d-flex justify-content-center pt-3">
                    <div className="e-auth-form-wrapper">
                        <form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <InputBox
                                        label="Full Name*"
                                        value={props.name}
                                        type="text"
                                        valueChange={(value) => {
                                            props.setName(value);
                                            setError(null);
                                        }}
                                        placeholder="Full Name"
                                        required={true} />
                                </div>
                                <div className="col-lg-6 pt-2">
                                    <InputBox
                                        label="Email*"
                                        value={props.email}
                                        type="email"
                                        valueChange={(value) => {
                                            props.setEmail(value);
                                            setError(null);
                                        }}
                                        placeholder="Email Address"
                                        required={true} />
                                </div>
                                <div className="col-lg-6 pt-2">
                                    <NumberInput
                                        label="Phone Number*"
                                        value={props.number}
                                        type="tel"
                                        valueChange={(value) => {
                                            props.setNumber(value);
                                            setError(null);
                                        }}
                                        placeholder="00000 00000"
                                        defaultCode="+91"
                                        onCodeChange={(val) => props.setCountryCode(val ? val : "+91")}
                                        required={true} />
                                </div>
                                <div className="col-lg-6 pt-2">
                                    <InputBox
                                        label="Password*"
                                        value={props.password}
                                        type="password"
                                        valueChange={(value) => {
                                            props.setPassword(value);
                                            setError(null);
                                        }}
                                        placeholder="New Password"
                                        required={true}
                                       />
                                </div>
                                <div className="col-lg-6 pt-2">
                                    <InputBox
                                        label="Confirm Password*"
                                        value={props.confirmPassword}
                                        type="password"
                                        valueChange={(value) => {
                                            props.setConfirmPassword(value);
                                            setError(null);
                                        }}
                                        placeholder="Re-enter Password"
                                        required={true} />
                                </div>
                                <div className="col-lg-12 pt-2 pb-3">
                                    <div className="w-100 d-flex align-items-center">
                                        {/* <CheckBox label={<>I agree to all <a>terms and conditions</a></>} onChecked={() => { }} defaultChecked={true} /> */}
                                        {/* <CheckBox label={<>I agree to all <span>terms and conditions</span></>} onChecked={() => { }} defaultChecked={true} /> */}
                                        <CheckBox
                                            label={<>I agree to all <span>terms and conditions</span></>}
                                            onChecked={(checked) => {
                                                setAgreeTerms(checked); // Update state on checkbox change
                                                setError(null);
                                            }} 
                                            defaultChecked={agreeTerms} // Bind with state
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 pt-3">
                                    {
                                        props.name &&
                                            props.email &&
                                            props.number &&
                                            props.password &&
                                            props.confirmPassword ?
                                            <Primary className={signupLoader ? "e-btn-loader" : ""} name="Create Account" full_width={true} handleClick={(e) => handleSignup(e)} />
                                            :
                                            <Primary name="Create Account" full_width={true} disabled={true} />
                                    }
                                </div>
                                {/* <div className="col-lg-12 py-3 text-center">
                                    <p className="mb-0">Or sign up with</p>
                                </div>
                                <div className="col-lg-12 pt-2 pb-3">
                                    <div className="row gx-3 gy-3">
                                        <div className="col-sm-6">
                                            <SocialButton
                                                provider="google"
                                                name={<div className="d-flex align-items-center"><span className="e-google-signup me-2"></span>Google</div>}
                                                appId="YOUR_APP_ID"
                                                id="e-signup-g-btn" />
                                        </div>
                                        <div className="col-sm-6">
                                            <SocialButton
                                                provider="facebook"
                                                name={<div className="d-flex align-items-center"><span className="e-facebook-signup me-2"></span>Facebook</div>}
                                                appId="YOUR_APP_ID"
                                                id="e-signup-fb-btn" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-12 pt-3">
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0 me-1">Existing User?</p>
                                        <TextButton name="Login Now" blue={true} small={true} handleClick={() => { props.clearAll(); router.push('/auth/login'); }} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default withRouter(CreateAccount);