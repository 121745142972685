/*--------------
Properties    default    desc
type          --         possible type: ["web_2", "web_3", "look_alike", "enroachments", "redirections", "resolved", "social", "templates"]
value         0          value to show on card
---------------*/

/*Package importing*/
import React, { useEffect, useState } from 'react';
/*import style*/
import styles from '../../styles/components/OverviewCard.module.scss';
import blueCircle from '../../assets/images/loader/blueCircle.gif';
import $ from 'jquery';
import services from '../../services/apis/services';
const OverviewSocialDomain = (props) => {
	let _UI_ID="gsjds3773_";
	let [domainsInfo,setDomainsInfo]=useState([]);
	let [arList,setARList]=useState([]);
	let [pList,setPList]=useState([]);
	let [pCount,setPCount]=useState(0);
	let [reqStatus,setReqStatus]=useState(false);
	useEffect(() => {
		if(props.domain!=null && props.domain.length>0){
		setTimeout(() => {
			twitterARecords(props.domain);
		}, 1000)	
		}
        
    }, [props.domain]);
	
	const twitterARecords=(tmpDomain)=>{
		let url = 'dns/tw/ars';	// A records
        let data = {
            domain: tmpDomain
        };
		setReqStatus(true);
		props.setTableReqStatus(true);
		services(true, url, data).then((response) => {
            let res = response.data
			console.log(res);
			
            if (res.status === 1) {
               
				let IPList=res.resData.ARList;
				setPCount(IPList.length);
				props.setBookedTws(IPList);
				
				
            } else if (res.status === -1 || res.status === 0 || res.status === -9) {
               
               
            } else {
               
                
            }
			setReqStatus(false);
			props.setTableReqStatus(false);
		});
	}
	return (
        <div className={`${styles.e_overview_wrapper} ${styles.e_twitter}`}>
			
			<a  className="d-flex text-decoration-none" style={{"cursor":"pointer"}} role="button" onClick={()=> props.setITab(4)} >
                <div className={styles.e_icon}></div>
                <div className="ps-2 d-flex flex-column pt-1">
                    <h4 className="mb-2 text-decoration-none">
                        {"Enroachments"}
                    </h4>
                    <div className="d-flex align-items-end text-decoration-none">
                        <span className={styles.e_value}>
						
							{reqStatus && 
									<>
										<img src={blueCircle} style={{width:"32px"}}/>
									</>
							}									
							{!reqStatus && 
								<>
								{pCount}
								</>
							} 
						</span>
                        
                    </div>
                </div>
            </a>
		</div>
    )
}

export default OverviewSocialDomain;