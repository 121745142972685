import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Logofooter from '../../assets/logo/footer_logo.png';

const Footer = () => {
  const [year, setYear] = useState(2021);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, []);

  return (
    <footer className="e-footer text-light py-4">
      <div className="container">
        <div className="row align-items-center e-footer-container">
          <div className="col-12 col-md-3 text-center mb-3 mb-md-0">
            <Link to="/" className="d-flex justify-content-center align-items-center">
            {/* justify-content-md-start */}
              <img
                src={Logofooter}
                alt="Footer Logo"
                className="e-footer-logo"
                style={{ maxWidth: '150px', height: 'auto' }} // Adjusted logo sizing
              />
            </Link>
          </div>
          <div className="col-12 col-md-6 text-center d-flex justify-content-center mb-3 mb-md-0">
            <div className="d-flex justify-content-center flex-wrap">
                {/* justify-content-md-start  ContactUs*/}
              <Link to="/privacyPolicy" className="me-4 mb-2 mb-md-0">Contact</Link>
              <Link to="/privacyPolicy" className="me-4 mb-2 mb-md-0">Privacy Policy</Link>
              <Link to="/TermsAndConditions" className="me-3 mb-2 mb-md-0">Terms & Conditions</Link>
              <Link to="/Faq" className="mb-2 mb-md-0">FAQ's</Link>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center text-md-end">
            <span>Powered by <a href="https://evoqueinnovativelab.com/" target="_blank" rel="noreferrer" className="text-light">web23.io</a></span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <span>{`Copyright © ${year}. Web23 Pvt. Ltd.`}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
