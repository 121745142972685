import React, { useEffect } from 'react';

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return (
        <>
            <div className="container terms-conditions-container">
                <header className="terms-header">
                    <h2 className='terms_title main_heading'>Terms and Conditions</h2>
                </header>
                
                <main className="terms-content">
                    <p className='terms_para'>
                        These terms and conditions (“<strong>Terms</strong>”) outline the rules and regulations for the use of Web23’s website, services, and products. By accessing this website we assume you accept these Terms in full. Do not continue to use Web23’s website if you do not accept all the terms and conditions stated on this page.
                    </p>

                    <h3 className='terms_subtitle'>Intellectual Property Rights</h3>
                    <p className='terms_para'>
                        Unless otherwise stated, Web23 and/or its licensors own the intellectual property rights for all material on the website. All intellectual property rights are reserved.
                    </p>

                    <h3 className='terms_subtitle'>License to Use</h3>
                    <p className='terms_para'>
                        You may view and/or print pages from Web23’s website for your personal use subject to restrictions set in these Terms. You must not:
                    </p>
                    <ul className='terms_ul'>
                        <li>Republish material from the website</li>
                        <li>Sell, rent, or sub-license material from the website</li>
                        <li>Reproduce, duplicate, or copy material from the website</li>
                        <li>Redistribute content from Web23 (unless content is specifically made for redistribution)</li>
                    </ul>

                    <h3 className='terms_subtitle'>User Comments</h3>
                    <p className='terms_para'>
                        Certain parts of this website may offer users the opportunity to post and exchange opinions and information. Web23 does not filter, edit, or review comments prior to their presence on the website. Comments reflect the views and opinions of the person who posts their views.
                    </p>

                    <h3 className='terms_subtitle'>Limitation of Liability</h3>
                    <p className='terms_para'>
                        In no event shall Web23, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website.
                    </p>

                    <h3 className='terms_subtitle'>Changes to These Terms</h3>
                    <p className='terms_para'>
                        We may revise these Terms at any time. By using this website you are expected to review these Terms on a regular basis.
                    </p>

                    <h3 className='terms_subtitle'>Contact Us</h3>
                    <p className='terms_para'>
                        If you have any questions or comments about these Terms, please contact us at email address.
                    </p>
                </main>

                {/* <footer className="terms-footer">
                    <p className='terms_para'>&copy; 2024 Your Company. All rights reserved.</p>
                </footer> */}
            
            </div>
        </>
    )
}

export default TermsAndConditions;
