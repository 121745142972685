/*--------------
Properties    default    desc
data            []          data populates the dataTable.
---------------*/

/*Package importing*/
import React, { useEffect, useState } from 'react';

/*import style*/
import styles from '../../styles/components/HealthSummaryCard.module.scss';

/* assets */
import NotConnected from '../../assets/images/connect-illustration.svg';

/* components */
import { Primary, TextButton } from '../cta';
import { SingleSelect } from '../form';
import { HeathIndicatorCard, SecurityIndicatorCard } from '.';
import Loader from '../others/loader';

import BarChart from '../charts/BarChart'

/* services */
import services from '../../services/apis/services';
const HealthSummaryCard = (props) => {
    const [selectedValue, setSelectedValue] = useState({ value: 1, label: "Last 7 days" });
    const [summaryData, setSummaryData] = useState({});
    const [error, setError] = useState(null);
    const [loader,setLoader] = useState(false);

    useEffect(() => {
        getHealthSummary();
    }, [selectedValue]);

    const options = [
        {
            value: 1,
            label: "Last 7 days"
        },
        {
            value: 2,
            label: "Last 30 days"
        },
        {
            value: 3,
            label: "Last year"
        }
    ];



    /*--------- get health summary ---*/
    const getHealthSummary = () => {
        setLoader(true);
        var url = 'rptdata/userhealth/bylast7days';
        var date = new Date();

        if (selectedValue.value === 2) {
            var url = 'rptdata/userhealth/bymonth';
        } else if (selectedValue.value === 3) {
            var url = 'rptdata/userhealth/bylast12months';
        }

        var data = {
            token: localStorage.getItem("web23_token"),
        }

        if (selectedValue.value === 2) {
            var data = {
                token: localStorage.getItem("web23_token"),
                month: date.getMonth() + 1
            }
        } else {
            var data = {
                token: localStorage.getItem("web23_token"),
            }
        }

        services(true, url, data).then((response) => {
            let res = response.data;

            if (res.status === 1) {
                setSummaryData(res.resData);
                setLoader(false);
            } else {
                setError(res.resData[0].msg);
                if(res.resData[0].msg === "Session has been expired"){
                    localStorage.clear();
                    window.location.href = '/auth/login';
                }
                setLoader(false);
            }
        })
    }

    return (
        <div>
            <div className={`${styles.e_report_wrapper}`}>
                <div className={`${styles.e_report_header}`}>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="d-flex flex-column flex-md-row h-100 w-100 align-items-md-center align-items-start">
                                <h3 className="mb-0 pe-4 text-nowrap main_heading">Health Summary</h3>
                                <div className={`${styles.e_select_wrapper} w-100 h-100 d-flex align-items-center pt-3 pt-md-0 justify-content-start`}>
                                    <span className={styles.e_select_indicator}>Show:</span>
                                    <div className={`${styles.e_select_container} ms-1`}>
                                        <SingleSelect defaultValue={selectedValue} placeholder="This month" options={options} selectchange={(temp) => setSelectedValue(temp)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 pt-2 pt-lg-0">
                            <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                                <TextButton name="View Reports" className="mx-3" forward={true} handleClick={props.redirect} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    Object.values(summaryData).length > 0 && loader === false ?
                        <div className={`${styles.e_report_body}`}>
                            <div className="row gy-3">
                                <div className="col-lg-3 e-border-right d-lg-block d-none">
                                    <div className="d-flex align-items-end justify-content-start">
                                        {/* <p className={styles.e_card_legend}>{summaryData?.securityScore?.value}</p> */}
                                        <div className="e-max-content-width">
                                            <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Security score</span>
                                            <SecurityIndicatorCard value={summaryData?.securityScore?.value} remarks={summaryData?.securityScore?.remarks} />
                                        </div>
                                        {/* <p className={styles.e_card_legend}>{summaryData?.securityScore?.value}</p> */}
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 d-lg-none">
                                    <div className="d-flex align-items-end justify-content-center">
                                        <p className={styles.e_card_legend}>{summaryData?.socialScore?.value}</p>
                                        <div className="e-max-content-width">
                                            <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Security score</span>
                                            <SecurityIndicatorCard value={summaryData?.socialScore?.value}  remarks = {summaryData?.socialScore?.value}/>
                                        </div>
                                        <p className={styles.e_card_legend}>300</p>
                                    </div>
                                    <div className="pt-3 e-border-bottom"></div>
                                </div> */}
                                <div className="col-lg-9">
                                    <div className="row justify-content-center gy-3">
                                        <div className="col-lg">
                                            <div className="e-max-content-width mx-auto">
                                                {process.env.REACT_APP_VISIBLE_WEB_3_0 ==='true' ?
                                                <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Web 2.0 Domains</span>
                                                :
                                                <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Web Domains</span>
                                                }
                                                <span>
                                                    <HeathIndicatorCard value={summaryData?.web2Score?.value} remarks={summaryData?.web2Score?.remarks} healthy={true} type="web2" />
                                                </span>
                                            </div>
                                        </div>
                                        {process.env.REACT_APP_VISIBLE_WEB_3_0==='true' &&
                                        <div className="col-lg">
                                            <div className="e-max-content-width mx-auto">
                                                <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Web 3.0 Domains</span>
                                                <span>
                                                    <HeathIndicatorCard healthy={true} type="web3" value={summaryData?.web3Score?.value} remarks={summaryData?.web3Score?.remarks}  />
                                                </span>
                                            </div>
                                        </div>
                                        }
                                        <div className="col-lg">
                                            <div className="e-max-content-width mx-auto">
                                                <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>social accounts</span>
                                                <span>
                                                    <HeathIndicatorCard healthy={true} type="social" value={summaryData?.socialScore?.value} remarks={summaryData?.socialScore?.remarks} />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg">
                                            <div className="e-max-content-width mx-auto">
                                                <span>
                                                    <BarChart dataValues={summaryData} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loader />
                }
            </div>
        </div>
    )
}

export default HealthSummaryCard;