import React, { useEffect } from 'react';

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);

  return (
    <>
        <div className="container privacy-policy-container">
            <header className="privacy-header">
                <h2 className='privacy_title main_heading'>Privacy Policy</h2>
            </header>
            
            <main className="privacy-content">
                <p className='privacy_para'>
                    This privacy policy (“<strong>Privacy Policy</strong>”) has been compiled to better serve those who are concerned with how their Personally Identifiable Information (“<strong>PII</strong>”) is being used by Web23. PII is information that can be used on its own or with other information to identify a natural person. Please read this Privacy Policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your PII when using or accessing our website, applications, or services.
                </p>

                <h3 className='privacysubtitle'>Information We Collect</h3>
                <p className='privacy_para'>
                We may collect information about you in a variety of ways, including personal data, usage data, and cookies.
                </p>

                <h3 className='privacysubtitle'>Use of Your Information</h3>
                <p className='privacy_para'>
                We use the information we collect in various ways, including to:
                </p>
                <ul className='privacy_ul'>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                </ul>

                <h3 className='privacysubtitle'>Disclosure of Your Information</h3>
                <p className='privacy_para'>
                We may share your information with third parties to provide you with our services.
                </p>

                <h3 className='privacysubtitle'>Contact Us</h3>
                <p className='privacy_para'>
                If you have any questions or comments about this Privacy Policy, please contact us at email address.
                </p>
            </main>

            {/* <footer className="privacy-footer">
                <p className='privacy_para'>&copy; 2024 Your Company. All rights reserved.</p>
            </footer> */}
        
        </div>
    </>
  )
}

export default PrivacyPolicy
