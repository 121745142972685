/*Package importing*/
import React, { useEffect, useState } from 'react';

/*import style*/
import styles from '../../styles/components/ToggleButton.module.scss';
import { getServices } from "../../services/apis/services";
import Swal from 'sweetalert2'; // Import SweetAlert

const ToggleButton = (props) => {

    const [isActive, setActive] = useState(false);

    useEffect(() => {
        if (props.defaultActive) setActive(props.defaultActive);
    }, [props.defaultActive]);

    const changeActiveState = async () => {        
        let newState = !isActive;
        setActive(newState);
        try {

            let url = "";
            if (props.web23 == 'web2') {
                url = "domains/autorenewal2";
                
            } else if (props.web23 == 'web3') {
                 url = "domains/autorenewal3";
                 
            }
  
            const token = localStorage.getItem("web23_token");
            const response = await getServices(url, token, {autorenwel:newState});
            if (response.data.status == 1 ) {
               
                Swal.fire({
                    title: "Autorenwewal",
                    text: "Autorenwewal updated successfully",
                    icon: "success"
                });

            } else {
                Swal.fire({
                    title: "Autorenwewal",
                    text: "Autorenwewal updation failed",
                    icon: "error"
                });

            }
        } catch (error) {
            console.log(error) 
        }


        try {
            props.onChange(newState);
        } catch (error) { console.log(error) }
    }

    return (
        <div>
            <div className={isActive ? `${styles.e_btn_wrapper} ${styles.e_btn_active}` : `${styles.e_btn_wrapper}`}>
                <div className="d-flex align-items-center">
                    <div className={`${styles.e_btn_container} position-relative`} onClick={() => changeActiveState()}>
                        <div className={`${styles.e_toggle} position-absolute`}></div>
                    </div>
                    <label className="px-2" onClick={() => changeActiveState()}>{
                        isActive ?
                            "On"
                            :
                            "Off"
                    }</label>
                    {
                        !props.hideInfo ?
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.83317 10.1432H2.83314L2.8332 10.1485C2.87559 14.0913 6.09502 17.2593 10.038 17.2382H10.038C13.9809 17.2169 17.1661 14.0145 17.1661 10.0715C17.1661 6.1285 13.9809 2.92614 10.038 2.90484L10.038 2.90484C6.09502 2.88375 2.87559 6.05168 2.8332 9.99446H2.83317V9.99984V10.1432ZM9.6665 10.8332V10.3332H9.1665H8.83317V9.6665H10.3332V12.4998V12.9998H10.8332H11.1665V13.6665H9.6665V10.8332ZM9.99929 17.8332C5.67331 17.8329 2.1665 14.3259 2.1665 9.99984C2.1665 5.67361 5.67361 2.1665 9.99984 2.1665C14.3259 2.1665 17.8329 5.67331 17.8332 9.99929C17.8284 14.3239 14.3239 17.8284 9.99929 17.8332ZM10.3332 6.99984H9.6665V6.33317H10.3332V6.99984Z" fill="#4660F1" stroke="#4660F1" />
                            </svg>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )
}

export default ToggleButton;