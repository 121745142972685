/*--------------
Properties    default    desc
type          --         possible type: ["web_2", "web_3", "look_alike", "enroachments", "redirections", "resolved", "social", "templates"]
value         0          value to show on card
---------------*/

/*Package importing*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/OverviewCard.module.scss';

const OverviewCard = (props) => {
    
    return (
        <div
            className={
                props.type == "web_2" ?
                    `${styles.e_overview_wrapper} ${styles.e_web_2}`
                    : props.type == "web_3" ?
                        `${styles.e_overview_wrapper} ${styles.e_web_3}`
                        : props.type == "look_alike" ?
                            `${styles.e_overview_wrapper} ${styles.e_look_alike}`
                            : props.type == "enroachments" ?
                                `${styles.e_overview_wrapper} ${styles.e_enroachments}`
                                : props.type == "redirections" ?
                                    `${styles.e_overview_wrapper} ${styles.e_redirections}`
                                    : props.type == "resolved" ?
                                        `${styles.e_overview_wrapper} ${styles.e_resolved}`
                                        : props.type == "social" ?
                                            `${styles.e_overview_wrapper} ${styles.e_social}`
                                            : props.type == "templates" ?
                                                `${styles.e_overview_wrapper} ${styles.e_templates}`
                                                : styles.e_overview_wrapper
            }>
            <div className="d-flex">
                <div className={styles.e_icon}></div>
                <div className="ps-2 d-flex flex-column pt-1">
                    <h4 className="mb-2">
                        {
                            !props.title && props.type == "web_2" ?
                                (process.env.REACT_APP_VISIBLE_WEB_3_0 === 'true' ? 
                                    `Your Web 2.0 Domains` 
                                    : `Your Web Domains`)
                                : !props.title && props.type == "web_3" ?
                                    `Your Web 3.0 Domains`
                                    : !props.title && props.type == "look_alike" ?
                                        `Look Alikes`
                                        : !props.title && props.type == "enroachments" ?
                                            `Total Encroachments`
                                            : !props.title && props.type == "redirections" ?
                                                `Provided Redirections`
                                                : !props.title && props.type == "resolved" ?
                                                    `Resolved Threats`
                                                    : !props.title && props.type == "social" ?
                                                        `Social Accounts`
                                                        : !props.title && props.type == "templates" ?
                                                            `Templates`
                                                            : props.title
                        }
                    </h4>
                    <div className="d-flex align-items-end">
                        <span className={styles.e_value}>{props.value ? props.value : 0}</span><span className={styles.e_currency}>{props.currency}</span>
                        {
                            props.suffix ?
                                <span className={`${styles.e_suffix} ms-1 mb-1`}>
                                    {props.suffix}
                                </span>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewCard;