/*Package importing*/
// import React, { Component } from 'react';
// import Chart from 'react-apexcharts';

// export default class PieChart extends React.Component {
//   constructor(props) {
//     super(props);
//       this.state = {
//         series: this.props.data,
//         options: {
//           chart: {
//             type: 'pie', // Changed to 'pie' for pie chart
//           },
//           dataLabels: {
//             enabled: false,
//           },
//           plotOptions: {
//             pie: {
//               expandOnClick: true, // Adds animation when pie slice is clicked
//             }
//           },
//           legend: {
//             fontWeight: 500,
//             fontSize: "12px",
//             lineHeight: "15px",
//             fontFamily: "'Lexend', sans-serif",
//             fontWeight: 500,
//             position: this.props.legendOnSide ? "right" : 'bottom'
//           },
//           colors: this.props.colors,
//           labels: this.props.labels,
//           tooltip: {
//             style: {
//               fontFamily: "'Lexend', sans-serif",
//               fontWeight: 700,
//             },
//             fillSeriesColor: false,
//             y: {
//               formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
//                 return value + "%";
//               }
//             }
//           },
//           stroke: {
//             width: 0,
//           },
//         },
//       };
//     }
//   render() {
//     return (
//       <div className="pie">
//         <Chart options={this.state.options} series={this.state.series} type="pie" width="100%" height="500"/>
//       </div>
//     );
//   }
// }


/*Package importing*/
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

export default class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.data, // This should be an array of numbers
      options: {
        chart: {
          type: 'pie',
        },
        dataLabels: {
          enabled: true, // Enable data labels on the pie chart slices
          formatter: function (val, opts) {
            return val.toFixed(2) + "%"; // Display value as percentage
          },
          style: {
            fontSize: '14px',
            fontFamily: "'Lexend', sans-serif",
            fontWeight: 700,
            colors: ['#fff'], // Color of the text inside slices (adjust as needed)
          },
          dropShadow: {
            enabled: false, // Disable drop shadow for better readability
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            dataLabels: {
              offset: -10, // Align the data label directly on the slice
              minAngleToShowLabel: 10, // Only show labels if the slice is big enough
            }
          }
        },
        legend: {
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "15px",
          fontFamily: "'Lexend', sans-serif",
          fontWeight: 500,
          position: this.props.legendOnSide ? "right" : 'bottom'
        },
        colors: this.props.colors,
        labels: this.props.labels,
        tooltip: {
          style: {
            fontFamily: "'Lexend', sans-serif",
            fontWeight: 700,
          },
          fillSeriesColor: false,
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return value + "%";
            }
          }
        },
        stroke: {
          width: 0,
        },
      },
    };
  }

  render() {
    return (
      <div className="pie">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          width="100%"
          height="500"
        />
      </div>
    );
  }
}
