import React, { useEffect, useState } from "react";
import { HeathIndicatorCard, OverviewCard } from ".";
import { ReportCards } from "../../components/cards";
/*import style*/
import styles from '../../styles/components/DomainHealthSummary.module.scss';
import { TextButton } from "../cta";

import { getServices } from "../../services/apis/services";

import { useParams, useLocation } from 'react-router-dom';

import Loader from '../others/loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import the specific icons you want to use
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const DomainHealthSummary = (props) => {

    const { LastCheck } = props;

    const { type } = useParams();
    
    // Extract query parameters
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const urldomain = queryParams.get('domain');
    let domain_name = urldomain

    const [domainsData, setDomainsData] = useState([]);
    const [issuesData, setIssuesData] = useState([]);
    const [matchedDomain, setMatchedDomain] = useState(null);
    const [domainInfo, setDomainInfo] = useState(null)
    const [domainId, setDomainId] = useState(null);
    const [failedCount, setFailedCount] = useState(0);

    const [filterRecomndedDataAlike, setFilterRecomndedDataAlike] = useState([]);
    const [domainsDataRecomanded, setDomainsDataRecomanded] = useState([]); 
    const [domainsDataRecomandedAlikeCount, setDomainsDataRecomandedAlikeCount] = useState(); 

    const [loadingIssues, setLoadingIssues] = useState(true);
    const [loadingDomains, setLoadingDomains] = useState(true);

    useEffect(() => {
        const fetchDomains = async () => {
            

                try {
                    const url = "reports/getIssueDetailsAllDashboardViewReports2";
                    const token = localStorage.getItem("web23_token");
                    const response = await getServices(url, token, { domain_name });
                    // setIssuesData( response.data.resData);

                    const issuesData = response.data.resData;

                    // Count how many issues have the status 'Failed'
                    const failedIssuesCount = issuesData.filter(issue => issue.status === 'Failed' || issue.status !== 'Resolved').length;

                    setIssuesData(issuesData);
                    setFailedCount(failedIssuesCount);
                    setLoadingIssues(false);

            } catch (error) {
                console.error("Error fetching domains", error);
                setLoadingIssues(false);
            }
        };

        const fetchDomainsAlikes = async () => {
            try {
                // setLoader(true);
                setDomainsDataRecomanded([]);
                
                const urlnew = "gd/domain/search";
                const token = localStorage.getItem("web23_token");
                const responseNewAvlDomain = await getServices(urlnew, token, { domainName: domain_name }); 
                const dataForProcess = responseNewAvlDomain.data.resData.domains;
                const filterRecomndedDataAlike = dataForProcess.filter(data => data.available === true);
                console.log("filterRecomndedDataAlikee",filterRecomndedDataAlike);
                setFilterRecomndedDataAlike(filterRecomndedDataAlike);

                setDomainsDataRecomandedAlikeCount(filterRecomndedDataAlike.length);
                setLoadingDomains(false);

            } catch (error) {
                // setLoader(false);
                console.error("Error fetching domain look-alikes", error);
                setLoadingDomains(false);
            }
        };

        fetchDomains();
        fetchDomainsAlikes();
        
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; 
    const totalPages = Math.ceil(filterRecomndedDataAlike.length / itemsPerPage);

    const currentItems = filterRecomndedDataAlike.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
    );

    const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };


    const [currentIssuesPage, setCurrentIssuesPage] = useState(1);
    const issuesPerPage = 5; 
    const totalIssuesPages = Math.ceil(issuesData.length / issuesPerPage);

    const currentIssues = issuesData.slice(
    (currentIssuesPage - 1) * issuesPerPage,
    currentIssuesPage * issuesPerPage
    );

    const handlePreviousIssues = () => {
    if (currentIssuesPage > 1) setCurrentIssuesPage(currentIssuesPage - 1);
    };

    const handleNextIssues = () => {
    if (currentIssuesPage < totalIssuesPages) setCurrentIssuesPage(currentIssuesPage + 1);
    };



    return (
        <div>
            <div className={`${styles.e_report_wrapper}`}>
                <div className={`${styles.e_report_header}`}>
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="mb-0 text-nowrap">Health Summary</h3>
                        </div>
                        <div className="col-lg-6 pt-3 pt-lg-0">
                            <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                                <TextButton name="Recheck" recheck={true} handleClick={props.handleRefresh}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.e_report_body}`}>
                    <div className="row gy-3">
                        <div className="col-lg-2 pe-0">
                            <div className="e-max-content-width">
                                <span className={`${styles.e_indicator_title} pb-3 text-uppercase d-flex justify-content-center`}>Health indicator</span>
                                <span>
                                    <HeathIndicatorCard value={1} />
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 ps-0">
                            <div className="w-100 h-100 d-flex align-items-center">
                                {/* <OverviewCard type="resolved" title="Last Check" value="2" suffix="ago" currency="hr"/> */}
                                <OverviewCard type="resolved" title="Last Check" value={LastCheck} suffix="ago" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="w-100 h-100 d-flex align-items-center">
                                <OverviewCard type="look_alike" title="Look Alikes" value={domainsDataRecomandedAlikeCount} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="w-100 h-100 d-flex align-items-center">
                                <OverviewCard type="enroachments" title="Unresolved Issues" value={failedCount} />
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12">
                            <div className={`${styles.e_list_wrapper} py-3`}>
                                <h4 className="mb-4 px-3">Summary</h4>
                                <div className={styles.e_list_container + " px-3"}>
                                    {loadingDomains ? (
                                    <Loader />
                                    ) : currentIssues.length > 0 ? (
                                    <table className="table table-responsive table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Issue</th>
                                            <th>Status</th>
                                            <th>Solved Date</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentIssues.map((issue, index) => (
                                            <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{issue.issue}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                <span className={styles.e_status}>{issue.status}</span>
                                                <div
                                                    className={`${styles.e_status_icon} ${
                                                    styles[
                                                        `e_status_${issue.status
                                                        .toLowerCase()
                                                        .replace(" ", "_")}`
                                                    ]
                                                    } ms-1`}
                                                ></div>
                                                </div>
                                            </td>
                                            <td>
                                                {issue.updatedAt
                                                ? new Date(issue.updatedAt).toLocaleDateString("en-GB")
                                                : "--"}
                                            </td>
                                            <td>
                                                <TextButton
                                                name={
                                                    issue.status === "Resolved"
                                                    ? "Recheck"
                                                    : "Recheck & verify"
                                                }
                                                recheck={true}
                                                small={true}
                                                />
                                            </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    ) : (
                                        <h3 className="d-flex justify-content-center align-items-center noissuesFound py-1">
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="text-warning me-3" />
                                                <h3 className="h5 noissuesFound">Till now, none of issues found!</h3>
                                            </div>
                                        </h3>
                                    )}

                                    {/* Pagination Controls */}
                                    {currentIssues.length > 0 && (
                                    <div className="d-flex justify-content-center my-3">
                                        <button
                                        className="btn btn-primary mx-2"
                                        onClick={handlePreviousIssues}
                                        disabled={currentIssuesPage === 1}
                                        >
                                        Previous
                                        </button>
                                        <span className="mx-3">
                                        Page {currentIssuesPage} of {totalIssuesPages}
                                        </span>
                                        <button
                                        className="btn btn-primary mx-2"
                                        onClick={handleNextIssues}
                                        disabled={currentIssuesPage === totalIssuesPages}
                                        >
                                        Next
                                        </button>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row pt-4">
                        <div className="col-12">
                            <div className={`${styles.e_list_wrapper} py-3`}>
                                <h4 className="mb-4 px-3">Look Alikes</h4>
                                <div className={styles.e_list_container + " px-3"}>
                                    {loadingDomains ? (
                                        <Loader />
                                    ) : currentItems.length > 0 ? (
                                    <table className="table table-hover table-striped table-bordered table-box-sha">
                                        <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Domain Name</th>
                                            <th>Cost</th>
                                            <th>Sataus</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        
                                            {currentItems.map((issue, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{issue.domain}</td>
                                                <td>{issue.currency}</td>
                                                <td>{issue.available ? "Available" : "Not Available"}</td>
                                            </tr>
                                           ))}
                                        </tbody>
                                    </table>
                                    ) : (
                                        <h3 className="d-flex justify-content-center align-items-center nodataFound py-1">
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faSearch} size="2x" className="mb-3 text-muted" />
                                                <p className="h5 text-muted">Oops! No data found</p>
                                                <p className="text-secondary">We couldn't find any records. Please check again later.</p>
                                            </div>
                                        </h3>
                                    )}
                                </div>

                                {currentItems.length > 0 && (
                                <div className="d-flex justify-content-center my-3">
                                    <button 
                                        className="btn btn-primary mx-2" 
                                        onClick={handlePrevious} 
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <span className="mx-3">Page {currentPage} of {totalPages}</span>
                                    <button 
                                        className="btn btn-primary mx-2" 
                                        onClick={handleNext} 
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </div>
                                )}

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

            
    )
}

export default DomainHealthSummary;