/* Packages */
// import React, { useEffect, useState } from 'react';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

/* Components */
import { Primary, TextButton } from '../cta';
import { InputBox } from '../form';
// import { NumberInput } from '../form';

/* Services */
// import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';
import services from '../../services/apis/services'
import { Toast } from '../alerts';

const ForgotPassword = (props) => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const router = useHistory();

    // const getOtp = async (e) => {
    //     e.preventDefault();
    //     let phoneValid = await PhoneValidation(props.number);
    //     if (phoneValid) {
    //         forgotPassword();
    //     }
    // }

    
    // const forgotPassword = () => {
    //     setLoader(true);

    //     let url = 'action/get/otp/forgotpwd';

    //     let data = {
    //         countryCode: props.countryCode,
    //         mobileNo: props.number
    //     };

    //     services(true, url, data).then((response) => {
    //         let res = response.data;
    //         if (res.status === 1) {
    //             props.setRedirect('forgot-password');
    //             router.push('/auth/verify-otp', { token: res.resData.otpToken });
    //             setLoader(false);
    //         } else {
    //             setError(res.resData[0].msg);
    //             setLoader(false);
    //         }
    //     });
    // }

    const getOtp = async (e) => {
        e.preventDefault();
    
        // Debugging logs

        // let data = {
        //     countryCode: props.countryCode,
        //     mobileNo: props.number
        // };
        // console.log("Get OTP clicked, phone number:", props.countryCode+props.number);
        console.log("Get OTP clicked, Email Id:", props.email);
    
      
        try {
            let emailValid = await EmailValidation(props.email);
            if (emailValid) {
                forgotPassword();
            } else {
                console.error("Email validation failed");
            }
        } catch (error) {
            console.error("Error during email validation or OTP request", error);
        }
    }
    
    const forgotPassword = () => {
        setLoader(true);
        let url = 'action/get/otp/forgotpwdbasedonemail';
        let data = {
            // countryCode: props.countryCode,
            // mobileNo: props.number
            email: props.email
        };
    
        services(true, url, data).then((response) => {
            let res = response.data;
            if (res.status === 1) {
                props.setRedirect('forgot-password');
                router.push('/auth/verify-otp', { token: res.resData.otpToken });
            } else {
                setError(res.resData[0].msg);
            }
            setLoader(false);
        }).catch(error => {
            console.error("Error in forgotPassword API call", error);
            setLoader(false);
        });
    }
    

    return (
        <div className="container-fluid p-0">
            <div className="e-auth-container w-100">
                <div className="e-auth-max-width-container mx-auto position-relative">
                    <h3 className="text-center mb-3">Forgot Password</h3>
                    <span className="position-absolute e-fp-back-btn">
                        <TextButton name="Back" back={true} small={true} handleClick={() => { props.clearAll(); router.push('/auth/login'); }} />
                    </span>
                </div>
                <div className="w-100 d-flex justify-content-center pt-3">
                    <div className="e-auth-form-wrapper">
                        <div className="row">
                            <form>
                                <div className="col-lg-12">
                                    {/* <NumberInput
                                        label="Phone Number"
                                        value={props.number}
                                        type="number"
                                        valueChange={(value) => props.setNumber(value)}
                                        placeholder="00000 00000"
                                        defaultCode="+91"
                                        onCodeChange={(val) => props.setCountryCode(val ? val : "+91")}
                                        required={true} /> */}

                                    <InputBox
                                        label="Email"
                                        value={props.email}
                                        type="email"
                                        valueChange={(value) => props.setEmail(value)}
                                        placeholder="Please Enter Register Email Id"
                                        required={true} />
                                </div>
                                <div className="col-lg-12 pt-3">
                                    {
                                        props.email ?
                                            <Primary name="Get OTP" className={loader ? "e-btn-loader" : ""} full_width={true} handleClick={(e) => getOtp(e)} />
                                            :
                                            <Primary name="Get OTP" full_width={true} disabled={true} />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                    />
                    :
                    null
            }
        </div>
    )
}

export default ForgotPassword;