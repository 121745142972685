import React, { useEffect, useState } from "react";
import { OverviewCard } from ".";
// import axios from "axios";
import styles from "../../styles/components/ReportOverviewCard.module.scss";
import DonutChart from "../charts/pie";
import PieChart from "../charts/pieChart";
// import services, { getServices } from "../../services/apis/services";
import { getServices } from "../../services/apis/services";


const ReportOverviewCard = () => {
  const [indicatorActive, setIndicatorActive] = useState(false);
  const [topBuffer, setTopBuffer] = useState(0);
  const [leftBuffer, setLeftBuffer] = useState(0);

  const [totals, setTotals] = useState({
    domain2count: 0,
    domain3count: 0,
    social_accounts_count: 0,
    threats_resolved_count: 0,
  });
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const alignToChartCenter = () => {
    let donutChart = document.querySelector(".e-donut-chart");
    let centerCircle = donutChart.querySelectorAll("circle");
    let chartBounds = donutChart.getBoundingClientRect();
    let circleBounds = centerCircle[0].getBoundingClientRect();
    let left = circleBounds.left - chartBounds.left + circleBounds.width / 2;
    let top = circleBounds.top - chartBounds.top + circleBounds.height / 2;
    setLeftBuffer(`${left}px`);
    setTopBuffer(`${top}px`);
    setIndicatorActive(true);
  };

  useEffect(() => {
    let url = "reports/getAllDashboardViewReports";
    let token = localStorage.getItem("web23_token");
    getServices(url, token).then((response) => {
      try {
        // let res = response;
        // console.log("res", res.data.resData);
        if (response.data.status !== 1) {
          console.error("Error from server:", response.data.msg);
          setError(new Error(response.data.msg));
        } else {
          const aggregatedTotals = response.data.resData.data.reduce(
            (acc, item) => {
              acc.domain2count += item.domain2count || 0;
              acc.domain3count += item.domain3count || 0;
              acc.social_accounts_count += item.social_accounts_count || 0;
              acc.threats_resolved_count += item.threats_resolved_count || 0;
              return acc;
            },
            {
              domain2count: 0,
              domain3count: 0,
              social_accounts_count: 0,
              threats_resolved_count: 0,
            }
          );

          setTotals(aggregatedTotals);
          setIsLoaded(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  }, []);

  return (
    <div className={styles.e_card_wrapper}>
      <div className="w-100 e-px-32 e-py-32 e-border-bottom">
        <h3 className="mb-0">Reports Overview</h3>
      </div>
      <div className="w-100 e-px-32 e-py-32">
        <div className="row gx-5 gy-4">
          <div className="col-lg-7 e-border-right pe-lg-5">
            <div className="row gy-3">
              <div className="col-lg-6">
                <OverviewCard
                  type="web_2"
                  value={parseInt(totals.domain2count)}
                />
              </div>
              {process.env.REACT_APP_VISIBLE_WEB_3_0 ==='true'&&
              <div className="col-lg-6">
                <OverviewCard type="web_3" value={totals.domain3count} />
              </div>
              }
              <div className="col-lg-6">
                <OverviewCard
                  type="social"
                  value={totals.social_accounts_count}
                />
              </div>
              <div className="col-lg-6">
                <OverviewCard
                  type="resolved"
                  value={totals.threats_resolved_count}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
          {process.env.REACT_APP_VISIBLE_WEB_3_0 ==='true' ?
            <div className={`e-donut-chart position-relative`}>
              <h6
                className={`${styles.e_indicator_title} pb-3 text-uppercase ms-5 ps-5`}
              >
                Accounts Snapshot
              </h6>

              {isLoaded && (
                <PieChart
                  data={[
                    parseInt(totals.domain2count) || 0,
                    parseInt(totals.domain3count) || 0,
                    parseInt(totals.social_accounts_count) || 0,
                  ]}
                  labels={[
                    "Web 2.0 Domains",
                    "Web 3.0 Domains",
                    "Social Accounts",
                  ]}
                  colors={["#4660F1", "#9111E1", "#2ACBEB"]}
                  legendOnSide={true}
                  isThin={true}
                />
              )}

              {isLoaded && (
                <div
                  className={
                    indicatorActive
                      ? `${styles.e_indicator_circle} ${styles.e_indicator_active} position-absolute d-flex flex-column align-items-center justify-content-center`
                      : `${styles.e_indicator_circle} position-absolute d-flex flex-column align-items-center justify-content-center`
                  }
                  style={{ top: topBuffer, left: leftBuffer }}
                >
                  <h6>
                    {totals.domain2count +
                      totals.domain3count +
                      totals.social_accounts_count}
                  </h6>
                  <span className={styles.e_indicator_label}>Accounts</span>
                </div>
              )}
            </div> :
            <div className={`e-donut-chart position-relative`}>
              <h6
                className={`${styles.e_indicator_title} pb-3 text-uppercase ms-5 ps-5`}
              >
                Accounts Snapshot
              </h6>

              {isLoaded && (
                <PieChart
                  data={[
                    parseInt(totals.domain2count) || 0,
                    parseInt(totals.social_accounts_count) || 0,
                  ]}
                  labels={[
                    "Web Domains",
                    "Social Accounts",
                  ]}
                  colors={["#4660F1", "#9111E1", "#2ACBEB"]}
                  legendOnSide={true}
                  isThin={true}
                />
              )}

              {isLoaded && (
                <div
                  className={
                    indicatorActive
                      ? `${styles.e_indicator_circle} ${styles.e_indicator_active} position-absolute d-flex flex-column align-items-center justify-content-center`
                      : `${styles.e_indicator_circle} position-absolute d-flex flex-column align-items-center justify-content-center`
                  }
                  style={{ top: topBuffer, left: leftBuffer }}
                >
                  <h6>
                    {totals.domain2count +
                      totals.social_accounts_count}
                  </h6>
                  <span className={styles.e_indicator_label}>Accounts</span>
                </div>
              )}
            </div>
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportOverviewCard;
