import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    // Ensures the page scrolls to the top after rendering
    setTimeout(() => {
      window.scrollTo(0, 0);  // Scroll to top after small delay
    }, 100);
  }, []); 

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setFormErrors({ ...formErrors, [id]: '' }); // Clear error for the specific field
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name.trim()) {
      errors.name = 'Please enter your name';
    }
    if (!formData.email) {
      errors.email = 'Please enter your email';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Please enter a valid email';
    }
    if (!formData.message.trim()) {
      errors.message = 'Please enter your message';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();  // Prevent default HTML5 validation
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);  
    } else {

      console.log('Form submitted:', formData);

      setFormData({ name: '', email: '', message: '' });

      Swal.fire({
        icon: 'success',
        title: 'Message Sent!',
        text: 'Thank you for contacting us. We will get back to you shortly.',
        confirmButtonColor: '#e9a8fe',
      });
    }
  };

  return (
    <>
      <div className="container-fluid contactus_bg_img">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="contact">
              <form className="contact__form" onSubmit={handleSubmit} noValidate> {/* Add noValidate */}
                <div className="contact__icon">
                  <FontAwesomeIcon icon={faPaperPlane} className="fa fa-paper-plane" />
                </div>
                <h2 className='d-flex justify-content-center mb-2 main_heading'>GET IN TOUCH !</h2>

                <label className="contact__label" htmlFor="name">Name</label>
                <input
                  type="text"
                  className={`contact__input ${formErrors.name ? 'error-input' : ''}`}
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                />
                {formErrors.name && <p className="error">{formErrors.name}</p>} {/* Custom error */}

                <label className="contact__label" htmlFor="email">Email</label>
                <input
                  type="email"
                  className={`contact__input ${formErrors.email ? 'error-input' : ''}`}
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {formErrors.email && <p className="error">{formErrors.email}</p>} {/* Custom error */}

                <label className="contact__label" htmlFor="message">Message</label>
                <textarea
                  className={`contact__input ${formErrors.message ? 'error-input' : ''}`}
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Enter your message"
                  rows="5"
                />
                {formErrors.message && <p className="error">{formErrors.message}</p>} {/* Custom error */}

                <button type="submit" className="contact__btn">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
