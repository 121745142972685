/*--------------
prop-type        card-type
1                Web2 Records Modal
2                Web3 Records Modal
---------------*/

import React, { useState,useEffect } from 'react';

import { Primary, Secondary } from '../cta';
import services from '../../services/apis/services';
import { InputBox, SingleSelect } from '../form';

const w2TypeOptions = [
    {value: "NS", label: "Nameserver"},
    {value: "A", label: "A"},
    {value: "CNAME", label: "CNAME"},
    {value: "MX", label: "MX"},
    {value: "TXT", label: "TXT"},
    {value: "SRV", label: "SRV"},
    {value: "AAAA", label: "AAAA"}  
    
]
const ttlList = [
	{value:600}, 
	{value:900}, 
	{value:1200}, 
	{value:1800}, 
	{value:3600}, 
	{value:7200}, 
	{value:3600*12},
	{value:3600*24}
]
const EditW2RecordModal = (props) => {
    const [error, setError] = useState(null);

    const [domain, setDomain] = useState("");
    const [type, setType] = useState("");
    const [value, setValue] = useState("");
    const [name, setName] = useState("");
    const [ttl, setTtl] = useState("");
    
	useEffect(()=>{
		if(props.record){
		
			setDomain(props.record.domain);
			setType(props.record.type);
			setName(props.record.name);
			setValue(props.record.value);
			setTtl(props.record.ttl);
			// console.log("CARD is OPENED");
			console.log(props.record);
			document.getElementById("name").value=props.record.name;
			document.getElementById("value").value=props.record.value;
			document.getElementById("ttl").value=props.record.ttl;
		}
	},[props.record]);
	const postRecord=(e)=>{
		e.preventDefault();
		let SESSION_TOKEN=localStorage.getItem("web23_token");
		let _form={
			
			token:SESSION_TOKEN ,
			id:props.record.slno,
			domain:domain,
			type:type,
			name:name,
			value:value,
			ttl:ttl
			
		};
		let _option={
					'headers': {
					  'method': 'post',
					  'Accept': 'application/json',
					  'Content-Type': 'application/json',
					}
			};
		let _url = 'dns/edit/record';
		services(true, _url, _form).then((response) => {
            let res = response.data;
			console.log(res);
		})
		.catch(function (response) {
		console.log(response);
		})
		
	}
    return (
        <div className="modal fade e-add-record-modal" id="editW2RecordModal" tabIndex="-1" aria-labelledby="addRecordModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title mb-0" id="addRecordModalLabel">Edit Record</h5>
                        <div className="e-close-btn-wrapper d-flex align-items-center justify-content-center position-absolute" data-bs-dismiss="modal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#11142F"/>
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-2">
                       
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="e-input-wrap">
                                        <label>Type <span className="e-warning-color">*</span></label>
                                        <input type='text' name='type' id='type' value={props.record.type} disabled={true} className='form-control' placeholder='type' />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
									<label>Name <span className="e-warning-color">*</span></label>
                                    <input type='text' name='name' id='name' onChange={(e)=>setName(e.target.value)} className='form-control' placeholder='@ or name' />
                                </div>
                               
                                <div className="col-lg-4 col-md-6">
                                    <div className="e-input-wrap">
                                        <label>TTL <span className="e-warning-color">*</span></label>
                                        <select className='form-select' name="ttl" id="ttl" onChange={(e)=>setTtl(e.target.value)}>
										<option value=''></option>
										{
											ttlList.map((item,i)=>{
												return(
													<option>{item.value}</option>
												)
											})
										}
										</select>
                                    </div>
                                </div>
								 <div className="col-md-12">
                                    <label>Value <span className="e-warning-color">*</span></label>
                                    <input type='text' name='value' id='value' onChange={(e)=>setValue(e.target.value)} className='form-control' placeholder='' />
                                </div>
                            </div>
                            
                    </div>
                    <div className="modal-footer pt-4 justify-content-end align-items-center d-flex">
                        <div className="d-flex align-items-center">
                            <button  className="px-4 py-2 me-3 btn btn-danger" name="Cancel" data-bs-dismiss="modal">Cancel</button>
                            <button  className="px-4 py-2 btn btn-primary" name="Save" onClick={(e)=>postRecord(e)} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditW2RecordModal;