// BarChart.js
import React from 'react';
import ApexCharts from 'react-apexcharts';

const BarChart = (props) => {

  let xaxis = {
    categories: [],
  }
 let categories = [];
 let dataCategories = [];

  if (props) {

    if(props.dataValues.web2Score) {
      categories.push("Web 2.0 Count")
      dataCategories.push(props.dataValues.web2Score.value)
    }

    if(props.dataValues.securityScore) {
      categories.push("2.0 Health")
      dataCategories.push(props.dataValues.securityScore.value)
    }

    if(process.env.REACT_APP_VISIBLE_WEB_3_0==='true') {
      if(props.dataValues.web2Score) {
        categories.push("Web 3.0 Count")
        dataCategories.push(props.dataValues.web3Score.value)
      }

      

    }
    xaxis.categories = categories

  }
  



  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false, // Disable the toolbar to remove the ApexCharts menu
      },
    },
    title: {
    //   text: 'Sample Bar Chart',
    },
    xaxis,
    yaxis: {
      title: {
        text: 'Counts',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: 'Series 1',
      data: dataCategories,
    },
  ];

  return (
    <div>
      <ApexCharts options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default BarChart;
