import React, { useState } from "react";

/* Import styles */
import styles from '../../styles/components/SecondaryDomainTable.module.scss';

import Success from '../../assets/images/icons/tick-icon-18.svg';
import Warning from '../../assets/images/icons/warning-icon-18.svg';
import { TextButton } from "../cta";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

const SecondaryDomainTable = (props) => {
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Change this to the desired number of items per page

    // Calculate total pages
    const totalPages = Math.ceil(props.data.length / itemsPerPage);

    // Slice the data based on the current page
    const paginatedData = props.data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Handle page navigation
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className={`${styles.e_table_wrapper} w-100`}>
            <div className={`${styles.e_table_container} py-3`}>
                <div className={`${styles.e_table_head} w-100`}>
                    <div className="w-100 px-3">
                        {
                            paginatedData && paginatedData.length ? (
                            
                            <table className="table w-100 table-hover table-striped table-bordered table-box-sha">
                                <thead>
                                    <tr>
                                        <th scope="col">Domain Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Expiry</th>
                                        <th scope="col">Redirections</th>
                                        <th scope="col">More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((item, i) => (
                                    <tr key={i}>
                                        <td className="text-lowercase">{item.name}</td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {
                                                    new Date(item.expiry) > new Date() ? (
                                                        <>
                                                        <img src={Success} alt="Success Icon" className="me-1" />
                                                        <span>Active</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                        <img src={Warning} alt="Warning Icon" className="me-1" />
                                                        <span>Inactive</span>
                                                        </>
                                                    )
                                                }
                                                {/* {
                                                    new Date(item.expiry) > new Date() ?
                                                    <img src={Warning} alt="Warning Icon" className="me-1" />
                                                    :
                                                    <img src={Success} alt="Success Icon" className="me-1" />
                                                }
                                                <span>{new Date(item.expiry) > new Date() ? "Active" : "Inactive"}</span> */}
                                            </div>
                                        </td>
                                        <td>{item.expiry}</td>
                                        <td>{item.redirections}</td>
                                        <td>
                                            <TextButton name="Manage" small={true} blue={true} />
                                        </td>
                                    </tr>
                                    ))}       
                                </tbody>
                            </table>
                            ) : (
                                <h3 className="d-flex justify-content-center align-items-center nosubdomain py-1">
                                    <div className="text-center">
                                        <FontAwesomeIcon icon={faFolderOpen} size="2x" className="text-muted me-3 mb-1" />
                                        <h3 className="h5">Till now, none of your Secondary Domains are present!</h3>
                                    </div>
                                </h3>
                            )
                        }
                    </div>
                </div>


                {/* Pagination Controls  btn-link */}
                {paginatedData && paginatedData.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center pt-4">
                        <button
                            className="btn text-primary"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            style={{
                                padding: "6px 12px",
                                border: "1px solid rgb(24, 144, 255)",
                                backgroundColor: "rgb(255, 255, 255)",
                                color: "rgb(24, 144, 255)",
                                borderRadius: "4px",
                                cursor: "pointer",
                                outline: "none"
                            }}
                        >
                            Previous
                        </button>
                        <p className="mb-0 mx-3" style={{ fontSize: "16px" }}>
                            Page {currentPage} of {totalPages}
                        </p>
                        <button
                            className="btn text-primary"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            style={{
                                padding: "6px 12px",
                                border: "1px solid rgb(24, 144, 255)",
                                backgroundColor: "rgb(255, 255, 255)",
                                color: "rgb(24, 144, 255)",
                                borderRadius: "4px",
                                cursor: "pointer",
                                outline: "none"
                            }}
                        >
                            Next
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default SecondaryDomainTable;
